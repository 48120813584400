import React from "react";
import { NavLink } from "react-router-dom";
/**
 * Renders the release notes for the site
 */
class ReleaseNotes extends React.Component {
  /**
   * When the component mounts, enable some nice smooth scrolling
   * for the anchors on the page
   */
  componentDidMount() {
    document.title = "Let's Play Bingo! | Release Notes";
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }

  render() {
    return (
      <section>
        <div className="back-to-top">
          <a href="#top">&#10094;</a>
        </div>
        <div className="container row">
          <div className="col colspan12 margin-bottom-md">
            <h1 id="top">
              Release Notes!
              <NavLink to="/" className={({ isActive }) => (isActive ? "hide" : "back-link")}>
                <button aria-label="Back to Game" className="primary-button">
                  Back to Game
                </button>
              </NavLink>
            </h1>
          </div>
        </div>
        <div className="container row desktop-no-wrap mobile-force-wrap">
          <div className="col colspan8">
            {/* ------------- Table of Contents ------------ */}
            <div className="table-of-contents margin-bottom-xxlg">
              <h3>Table of Contents</h3>
              <ul>
                <li>
                  <a href="#r10292024">10/29/2024 - Added patterns and updated the store</a>
                </li>
                <li>
                  <a href="#r582024">5/8/2024 - Added 100 patterns and fixed a bug</a>
                </li>
                <li>
                  <a href="#r2182024">2/18/2024 - Added patterns</a>
                </li>
                <li>
                  <a href="#r1162024">1/16/2024 - New light mode, rotating winning patterns and more!</a>
                </li>
                <li>
                  <a href="#r1102024">1/10/2024 - Fixed a bug with skip unused numbers</a>
                </li>
                <li>
                  <a href="#r12212023">12/21/2023 - New patterns and fix bug with pattern selection.</a>
                </li>
                <li>
                  <a href="#r12172023">12/17/2023 - New patterns and minor improvements.</a>
                </li>
                <li>
                  <a href="#r12102023">12/10/2023 - Submission form update and bugfix</a>
                </li>
                <li>
                  <a href="#r1292023">12/9/2023 - New patterns and additions to help page</a>
                </li>
                <li>
                  <a href="#r11132023">11/14/2023 - New audible calling option!</a>
                </li>
                <li>
                  <a href="#r1122023">11/2/2023 - New patterns and working on fixing the Submit A Pattern page.</a>
                </li>
                <li>
                  <a href="#r10292023">10/29/2023 - Update to card generation and android hotfix</a>
                </li>
                <li>
                  <a href="#r10252023">10/25/2023 - Hotfix for card generation issue</a>
                </li>
                <li>
                  <a href="#r10202023">10/20/2023 - Launch of the Let's Play Bingo Store</a>
                </li>
                <li>
                  <a href="#r1012023">10/1/2023 - New branding and layout improvements</a>
                </li>
                <li>
                  <a href="#r9132023">9/13/2023 - Pattern overlays on cards and new patterns!</a>
                </li>
                <li>
                  <a href="#r9122023">9/12/2023 - Performance improvements and offline mode!</a>
                </li>
                <li>
                  <a href="#r9102023">9/10/2023 - Performance updates, new settings for game play and bugfixes</a>
                </li>
                <li>
                  <a href="#r992023">9/9/2023 - New user submitted patterns</a>
                </li>
                <li>
                  <a href="#r832023">8/3/2023 - Icon changes and submit a pattern bugfix</a>
                </li>
                <li>
                  <a href="#r7102023">7/10/2023 - Minor improvements and a bugfix</a>
                </li>
                <li>
                  <a href="#r772023">7/7/2023 - Minor improvements</a>
                </li>
                <li>
                  <a href="#r762023">7/6/2023 - Minor improvements and new patterns</a>
                </li>
                <li>
                  <a href="#r682023">6/8/2023 - Performance and accessibility improvements</a>
                </li>
                <li>
                  <a href="#r612023">6/1/2023 - 6th anniversary edition!</a>
                </li>
              </ul>
            </div>

            {/* --------------- 10-29-2024 ------------------- */}
            <div className="release-block">
              <h2 id="r10292024">
                <span className="date">10/29/2024</span>
                <span className="spacer"></span>Added patterns and updated the store
                <span className="version">v.4.5.3</span>
                <span className="tag improvement">improvement</span>
              </h2>
              <ul>
                <li>Added patterns submitted between 5/8/2024 and 10/29/2024.</li>
                <li>Updated QR codes for donations and about page</li>
                <li>
                  Updated the{" "}
                  <a href="http://letsplaybingo.store" target="_blank" rel="noreferrer">
                    Let's Play Bingo Store
                  </a>{" "}
                  by renewing listings and updating prices to include shipping costs!
                </li>
              </ul>
            </div>

            {/* --------------- 5-8-2024 ------------------- */}
            <div className="release-block">
              <h2 id="r582024">
                <span className="date">5/8/2024</span>
                <span className="spacer"></span>Added 100 patterns and fixed a bug
                <span className="version">v.4.5.2</span>
                <span className="tag improvement">improvement</span>
              </h2>
              <ul>
                <li>Added 100 patterns submitted up to 5/8/2024.</li>
                <li>Fixed an issue with the Torch pattern where Bs and Os were being called when they should be skipped.</li>
              </ul>
            </div>

            {/* --------------- 2-18-2024 ------------------- */}
            <div className="release-block">
              <h2 id="r2182024">
                <span className="date">2/18/2024</span>
                <span className="spacer"></span>Added patterns
                <span className="version">v.4.5.1</span>
                <span className="tag improvement">improvement</span>
              </h2>
              <ul>
                <li>Added patterns submitted up to 2/18/2024.</li>
              </ul>
            </div>

            {/* --------------- 1-16-2024 ------------------- */}
            <div className="release-block">
              <h2 id="r1162024">
                <span className="date">1/16/2024</span>
                <span className="spacer"></span>New light mode, rotating winning patterns and more!
                <span className="version">v.4.5.0</span>
                <span className="tag release">release</span>
              </h2>
              <ul>
                <li>Added the option to rotate through potential winning patterns in the pattern display!</li>
                <li>Added the ability to define alternative winning patterns with all new pattern submissions.</li>
                <li>Added a new light mode option in the header bar.</li>
                <li>Added the option to show the pattern dropdown on the main screen.</li>
                <li>Added patterns submitted up to 1/16/2024.</li>
              </ul>
            </div>

            {/* --------------- 1-10-2024 ------------------- */}
            <div className="release-block">
              <h2 id="r1102024">
                <span className="date">1/10/2024</span>
                <span className="spacer"></span>Fixed a bug with skip unused numbers.
                <span className="version">v.4.4.9</span>
                <span className="tag bugfix">bugfix</span>
              </h2>
              <ul>
                <li>Fixed an issue where custom patterns would erroneously mark certain letters as unused and skip valid calls.</li>
                <li>Added patterns submitted prior to 1/10/2024.</li>
              </ul>
            </div>

            {/* --------------- 12-21-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r12212023">
                <span className="date">12/21/2023</span>
                <span className="spacer"></span>New patterns.
                <span className="version">v.4.4.8</span>
                <span className="tag bugfix">bugfix</span>
              </h2>
              <ul>
                <li>Added patterns submitted between 12/17 - 12/21.</li>
                <li>Fixed a bug where altering the pattern via clicks in the settings panel would cause the panel to close.</li>
              </ul>
            </div>

            {/* --------------- 12-17-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r12172023">
                <span className="date">12/17/2023</span>
                <span className="spacer"></span>New patterns and minor improvements.
                <span className="version">v.4.4.7</span>
                <span className="tag improvement">improvement</span>
              </h2>
              <ul>
                <li>Hid the shuffle button during gameplay to prevent a bug where if you clicked on the button during a game it would cause you to be unable to control your game anymore.</li>
                <li>Added several new patterns from user submissions.</li>
                <li>Made functional and stylistic updates to the pattern submission page.</li>
                <li>Added unused letters display on the patterns page.</li>
                <li>Made minor changes to some of the text around the site.</li>
              </ul>
            </div>

            {/* --------------- 12-10-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r12102023">
                <span className="date">12/10/2023</span>
                <span className="spacer"></span>Submission form update and bugfix
                <span className="version">v.4.4.6</span>
                <span className="tag bugfix">bugfix</span>
              </h2>
              <ul>
                <li>Updated the submission form and fixed an issue where form submissions were being received twice.</li>
              </ul>
            </div>

            {/* --------------- 12-9-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r1292023">
                <span className="date">12/9/2023</span>
                <span className="spacer"></span>New patterns and additions to help page
                <span className="version">v.4.4.5</span>
                <span className="tag improvement">improvement</span>
              </h2>
              <ul>
                <li>Added 32 additional patterns submitted by players and the ability to search based on the credited individual.</li>
                <li>Updated the known issues on the help page to include information about the new MP3 caller not working with automatic calling on certain mobile devices due to a security feature built into devices.</li>
              </ul>
            </div>

            {/* --------------- 11-14-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r11142023">
                <span className="date">11/14/2023</span>
                <span className="spacer"></span>New audible calling option!
                <span className="version">v.4.4.5</span>
                <span className="tag improvement">improvement</span>
              </h2>
              <ul>
                <li>
                  Added a new audible calling option! Now if you're having trouble with the speech synthesis caller you can choose the mp3 based caller as a back up. The caller options are limited to a masculine and a feminine voice for each language and are only available in English, Spanish and French at this time. This is a huge improvement for those who have been having issues with the original caller.
                  <br />
                  <br />
                  <strong>Note:</strong> for this to work on the downloaded app you must run through the whole game (call all balls) with your voice of choice while you have internet access before you turn WiFi off to ensure the audio files are downloaded/cached. I will be fixing this in a future release.
                </li>
              </ul>
            </div>

            {/* --------------- 11-2-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r1122023">
                <span className="date">11/2/2023</span>
                <span className="spacer"></span>
                New patterns and working on fixing the Submit A Pattern page.
                <span className="version">v.4.4.4</span>
                <span className="tag improvement">improvement</span>
              </h2>
              <ul>
                <li>Added new user submitted patterns.</li>
                <li>Note: the submit a pattern page is submitting duplicate entries or sometimes just dropping them in spam. So sometimes patterns are not received. Please submit your pattern again if it hasn't been added. I am working on a solution with my host as it is not my code causing it.</li>
                <li>Added spam fightning features to the forms.</li>
              </ul>
            </div>
            {/* --------------- 10-29-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r10292023">
                <span className="date">10/29/2023</span>
                <span className="spacer"></span>
                Update to card generation and android hotfix
                <span className="version">v.4.4.3</span>
                <span className="tag bugfix">bugfix</span>
              </h2>
              <ul>
                <li>Added an option on the card generation tool to reverse shaded area when printing a pattern on the card.</li>
                <li>Added a fix to address unexpected behaviors with voices with Chrome on Android devices.</li>
              </ul>
            </div>
            {/* --------------- 10-25-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r10252023">
                <span className="date">10/25/2023</span>
                <span className="spacer"></span>
                Hotfix for card generation issue
                <span className="version">v.4.4.2</span>
                <span className="tag bugfix">bugfix</span>
              </h2>
              <ul>
                <li>
                  Fixed a couple of issues with the card generation tool.
                  <ul>
                    <li>Adjusted the pattern overlay coloring on the card generation tool when using print in black and white to make it more readable.</li>
                    <li>Fixed an issue where custom pattern selection was not registering for the cards.</li>
                  </ul>
                </li>
                <li>Added some additional information on the help page and in the settings panel in regards to known issues with the audible bingo caller.</li>
              </ul>
            </div>
            {/* --------------- 10-20-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r10202023">
                <span className="date">10/20/2023</span>
                <span className="spacer"></span>
                New Gear Store!
                <span className="version">v.4.4.1</span>
                <span className="tag improvement">improvement</span>
              </h2>
              <ul>
                <li>
                  Added a new{" "}
                  <a href="http://letsplaybingo.store/" target="_blank" rel="noopener noreferrer">
                    Let's Play Bingo Store
                  </a>
                  !
                </li>
                <li>Made a panel in the settings dismissable to free up space.</li>
                <li>Made minor changes in the settings panel.</li>
                <li>Fixed a minor issue with the forms.</li>
                <li>Added new patterns.</li>
              </ul>
            </div>
            {/* --------------- 10-1-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r1012023">
                <span className="date">10/1/2023</span>
                <span className="spacer"></span>
                New branding and layout improvements
                <span className="version">v.4.4.0</span>
                <span className="tag release">release</span>
              </h2>
              <ul>
                <li>Brand new branding in honor of the upcoming 7th anniversary of Let's Play Bingo!</li>
                <li>Fixed responsive layout issues on smaller screens for all layouts.</li>
                <li>Added a setting for hiding the shuffle button.</li>
                <li>Fixed some issues with styling on the about page.</li>
              </ul>
            </div>
            {/* --------------- 9-15-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r9152023">
                <span className="date">9/15/2023</span>
                <span className="spacer"></span>
                Card generator and play along updates
                <span className="version">v.4.3.0</span>
                <span className="tag release">release</span>
              </h2>
              <ul>
                <li>Added a way to reset all game settings from the settings panel.</li>
                <li>Made adjustments to when manual is available and what is disabled when its turned on.</li>
                <li>Added a timer for manual calling mode that starting to count down after you enter a number to help callers time their calls better.</li>
                <li>Set a new limit on the number of cards you can generate/print at one time. A limit is required for performance considerations.</li>
                <li>Changed the number of card setting from a drop down to an input.</li>
                <li>Updated card styles to look a little nicer with the new pattern overlays.</li>
                <li>Added cacheing to the play along page so your settings are saved.</li>
                <li>Brought back shuffle!</li>
                <li>Added more user submitted patterns.</li>
              </ul>
            </div>
            {/* --------------- 9-13-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r9132023">
                <span className="date">9/13/2023</span>
                <span className="spacer"></span>
                Pattern overlays on cards and new patterns!
                <span className="version">v.4.2.2</span>
                <span className="tag improvement">improvement</span>
              </h2>
              <ul>
                <li>Added the ability to print pattern overlays during card generation.</li>
                <li>Added new user submitted patterns.</li>
                <li>Fixed a bug with the display of the pattern list.</li>
                <li>Fixed a bug on the Play Along page where daubed cards don't clear on new generation.</li>
                <li>Added cacheing to the card generation page so your settings are saved.</li>
              </ul>
            </div>
            {/* --------------- 9-12-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r9122023">
                <span className="date">9/12/2023</span>
                <span className="spacer"></span>
                Performance improvements and offline mode!
                <span className="version">v.4.2.1</span>
                <span className="tag improvement">improvement</span>
              </h2>
              <ul>
                <li>Upgraded the app to be downloadable and to work offline. See the help page for more info on how to download the app!</li>
                <li>Improved performance and accessibility by speeding up page load and fixing color contrast issues.</li>
              </ul>
            </div>
            {/* --------------- 9-10-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r9102023">
                <span className="date">9/10/2023</span>
                <span className="spacer"></span>
                Performance updates, new settings for game play and bugfixes
                <span className="version">v.4.2.0</span>
                <span className="tag release">release</span>
              </h2>
              <ul>
                <li>Added 2 new settings for disabling the main screen pattern display. You can disable it while in gameplay or disable it always so you can only select a new pattern from the settings panel. With neither of these options turned on the pattern display will always work. Defaults to disabled while in play.</li>
                <li>Fixed a bug with the pop out controls on all layouts except Classic where the previous calls would cause the entire form to break.</li>
                <li>Changed the way settings are built out to make it easier for future enhancements and maintainability.</li>
                <li>Fixed tablet and mobile styles on the submit a pattern form.</li>
              </ul>
            </div>
            {/* --------------- 9-9-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r992023">
                <span className="date">9/9/2023</span>
                <span className="spacer"></span>
                Add new patterns
                <span className="version">v.4.1.5</span>
                <span className="tag improvement">improvement</span>
              </h2>
              <ul>
                <li>Added more user submitted patterns.</li>
              </ul>
            </div>
            {/* --------------- 8-3-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r832023">
                <span className="date">8/3/2023</span>
                <span className="spacer"></span>
                Icon changes and submit a pattern bug fix
                <span className="version">v.4.1.4</span>
                <span className="tag bugfix">bugfix</span>
              </h2>
              <ul>
                <li>Fixed the submit a pattern form, it wasn't submitting the pattern data. If you submitted any patterns in July 2023, please re-submit.</li>
                <li>Changed out the old uiicons to the new font awesome icons that are a little clearer, especially the settings icon.</li>
                <li>Toggles have a new icon that looks like a toggle to show on and off state.</li>
                <li>Adjusted some minor styles throughout the site.</li>
                <li>Updated the labels for menu items to be clearer and re-arranged them.</li>
              </ul>
            </div>
            {/* --------------- 7-10-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r7102023">
                <span className="date">7/10/2023</span>
                <span className="spacer"></span>
                Minor improvements and a bugfix
                <span className="version">v.4.1.3</span>
                <span className="tag bugfix">bugfix</span>
              </h2>
              <ul>
                <li>Fixed broken link in the footer.</li>
                <li>Fix a bug with scrolling being disabled on pages if you navigate away from the main page with the settings panel open.</li>
                <li>Added more info to the patterns selection portion of the settings panel.</li>
                <li>Moved wild bingo into its own section in settings.</li>
                <li>Update some text and styles in the settings panel.</li>
                <li>Fix styling of dropdown menus.</li>
              </ul>
            </div>
            {/* --------------- 7-7-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r772023">
                <span className="date">7/7/2023</span>
                <span className="spacer"></span>
                Minor improvements and new patterns
                <span className="version">v.4.1.2</span>
                <span className="tag improvement">improvement</span>
              </h2>
              <ul>
                <li>Added the ability to filter patterns on the pattern page.</li>
                <li>Added alternative names to existing patterns to help find them in search.</li>
                <li>Added "improvement" tag for releases.</li>
                <li>Updated credit links.</li>
              </ul>
            </div>
            {/* --------------- 7-6-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r762023">
                <span className="date">7/6/2023</span>
                <span className="spacer"></span>
                Minor improvements and new patterns
                <span className="version">v.4.1.1</span>
                <span className="tag improvement">improvement</span>
              </h2>
              <ul>
                <li>Stylistic updates to clean up bugs in the UI</li>
                <li>Added several new user submitted patterns</li>
                <li>Updated the form for pattern submission to recognize when a pattern already exists and weed out bad submissions.</li>
                <li>Updated the contact link on the patterns page to lead to the pattern submission page.</li>
              </ul>
            </div>
            {/* --------------- 6-8-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r682023">
                <span className="date">6/8/2023</span>
                <span className="spacer"></span>
                Performance and accessibility improvements
                <span className="version">v.4.1.0</span>
                <span className="tag release">release</span>
              </h2>
              <p>I ran some tests on the performance and accessibility of the site and found a lot of areas for improvement.</p>
              <ul>
                <li>Contrasts have been updated for the visually impaired.</li>
                <li>Accessibility has been improved for screen readers by adding aria-labels.</li>
                <li>Adjusted loading of scripts to help save on loading times.</li>
                <li>Switched out the digital font for a smaller file that cuts down drastically on file size.</li>
                <li>Other minor improvements.</li>
              </ul>
            </div>
            {/* --------------- 6-1-2023 ------------------- */}
            <div className="release-block">
              <h2 id="r612023">
                <span className="date">6/1/2023</span>
                <span className="spacer"></span>
                6th anniversary edition!
                <span className="version">v.4.0.0</span>
                <span className="tag release">release</span>
              </h2>
              <p>
                This is a brand new edition of <span className="notranslate">Let's Play Bingo</span>. It has undergone a complete rewrite.
                <br />
                Check out some of the biggest improvements:
              </p>
              <ul>
                <li>The menu has been moved to a dropdown menu.</li>
                <li>The game settings have been moved off screen to a slide out panel to help clean up the main UI.</li>
                <li>
                  New settings panel with lots of new options!
                  <ul>
                    <li>Pattern selection</li>
                    <li>
                      100+ new patterns!
                      <ul>
                        <li>Pattern submission form!</li>
                      </ul>
                    </li>
                    <li>New Hot Ball feature!</li>
                    <li>New Wild Bingo modes</li>
                    <li>Full screen mode</li>
                    <li>Pop out game control box</li>
                    <li>Hide on screen controls</li>
                    <li>Current calls display</li>
                    <li>Previous calls display options</li>
                    <li>Countdown to next number</li>
                    <li>Lots of new card colors and new generation options.</li>
                    <li>Themes</li>
                    <li>Layouts</li>
                    <li>Hide footer</li>
                    <li>... and more!</li>
                  </ul>
                </li>
                <li>Full overhaul of the site design, giving users the ability to play how they want with only the info they want to show.</li>
                <li>Upgraded the technology used for bingo calls and card generation to now utilize the browser's built in cryptographic randomizer for better randomization.</li>
              </ul>
            </div>
          </div>
          <div className="col colspan4">
            <div className="release-aside transparent-background">
              <h2>Other Versions</h2>
              <div className="version-block">
                <h4>
                  2020 Edition <span className="version">v.3.0.0</span>
                </h4>
                <strong>Released:</strong> April 25, 2020
                <br />
                <strong>Retired:</strong> June 1, 2023
                <br />
                <strong>Link:</strong>{" "}
                <a href="https://2020.letsplaybingo.io" target="_blank">
                  2020 Edition
                </a>
                <br />
                <p>This was the 2nd edition built in ReactJS that featured a lot of upgrades from the classic edition. This edition is retired and will no longer be updated, but you can still use it at the link above.</p>
              </div>
              <div className="version-block">
                <h4>
                  Classic Edition <span className="version">v.2.0.0</span>
                </h4>
                <strong>Released:</strong> May 14, 2018
                <br />
                <strong>Retired:</strong> April 25, 2020
                <br />
                <strong>Link:</strong>{" "}
                <a href="https://classic.letsplaybingo.io" target="_blank">
                  Classic Edition
                </a>
                <br />
                <p>This was the 1st edition built in ReactJS. It is a very basic caller without a lot of features. It is rarely updated, only for serious issues.</p>
              </div>
              <div className="version-block">
                <h4>
                  Original Edition <span className="version">v.1.0.0</span>
                </h4>
                <strong>Released:</strong> January 1, 2017
                <br />
                <strong>Retired:</strong> May 14, 2020
                <br />
                <strong>Link:</strong>{" "}
                <a href="https://codepen.io/karolbrennan/pen/GxKZWX" target="_blank">
                  Original Edition
                </a>
                <br />
                <p>This was the very first edition of this bingo caller created in plain object oriented JavaScript. Currently lives on CodePen.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ReleaseNotes;
