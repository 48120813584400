export const dPatterns = [
  /* --------------------- D -------------------- */
  {
    value: "Daisy",
    label: "Daisy",
    otherNames: [],
    pattern: {
      B: [false, true, false, false, false],
      I: [true, false, true, false, false],
      N: [true, true, true, true, true],
      G: [true, false, true, false, false],
      O: [false, true, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Diagonal",
    label: "Diagonal",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [false, false, false, true, false],
      N: [false, false, true, false, false],
      G: [false, true, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: ["N"],
    credit: "Nija",
  },
  {
    value: "Diagonal (Alternate)",
    label: "Diagonal (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [false, true, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, true, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: ["N"],
    credit: "Jake",
  },
  {
    value: "Diagonal And Inside Four Corners",
    label: "Diagonal And Inside Four Corners",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [false, true, false, true, false],
      N: [false, false, true, false, false],
      G: [false, true, false, true, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: ["N"],
    credit: "Joe Pfanzelter",
  },
  {
    value: "Diagonal 'T' (Sorry No Free Space)",
    label: "Diagonal 'T' (Sorry No Free Space)",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, true, false, true, false],
      N: [false, false, false, false, false],
      G: [false, true, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: ["N"],
    credit: "Thomas J.",
    winningPatterns: [
      { B: [true, false, false, false, false], I: [false, true, false, false, false], N: [false, false, false, false, false], G: [false, true, false, true, false], O: [true, false, false, false, true] },
      { B: [false, false, false, false, true], I: [false, false, false, true, false], N: [false, false, false, false, false], G: [false, true, false, true, false], O: [true, false, false, false, true] },
      { B: [true, false, false, false, true], I: [false, true, false, true, false], N: [false, false, false, false, false], G: [false, false, false, true, false], O: [false, false, false, false, true] },
    ],
  },
  {
    value: "Diamond",
    label: "Diamond",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, true, false, true, false],
      N: [true, false, false, false, true],
      G: [false, true, false, true, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Diamond (Alternate)",
    label: "Diamond (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, false, false, false, false],
      N: [true, false, false, false, true],
      G: [false, false, false, false, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: ["I", "G"],
    credit: null,
  },
  {
    value: "Diamond (1 Away)",
    label: "Diamond (1 Away)",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, true, true, true, false],
      N: [false, true, true, true, true],
      G: [false, true, true, true, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
    winningPatterns: [
      { B: [false, false, true, false, false], I: [false, true, true, true, false], N: [true, true, true, true, true], G: [false, true, true, true, false], O: [false, false, false, false, false] },
      { B: [false, false, true, false, false], I: [false, true, true, true, false], N: [true, true, true, true, false], G: [false, true, true, true, false], O: [false, false, true, false, false] },
      { B: [false, false, false, false, false], I: [false, true, true, true, false], N: [true, true, true, true, true], G: [false, true, true, true, false], O: [false, false, true, false, false] },
    ],
  },
  {
    value: "Diamond (With Free Space)",
    label: "Diamond (With Free Space)",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, true, false, true, false],
      N: [true, false, true, false, true],
      G: [false, true, false, true, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Diamond Burst",
    label: "Diamond Burst",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, true],
      I: [false, true, false, true, false],
      N: [true, false, false, false, true],
      G: [false, true, false, true, false],
      O: [true, false, true, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Diamond Filled",
    label: "Diamond Filled",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, true, true, true, false],
      N: [true, true, true, true, true],
      G: [false, true, true, true, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Diamond Inside",
    label: "Diamond Inside",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, true, false, false],
      N: [false, true, true, true, false],
      G: [false, false, true, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Dice 5",
    label: "Dice 5",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, false, false, false, false],
      N: [false, false, true, false, false],
      G: [false, false, false, false, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: ["I", "N", "G"],
    credit: null,
  },
  {
    value: "Dog",
    label: "Dog",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [false, false, true, true, false],
      N: [false, false, true, false, false],
      G: [false, false, true, true, false],
      O: [false, true, false, false, false],
    },
    unusedLetters: ["N"],
    credit: "Michelle Langer",
  },
  {
    value: "Dog Bone",
    label: "Dog Bone",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [false, false, true, false, false],
      N: [false, false, true, false, false],
      G: [false, false, true, false, false],
      O: [false, true, true, true, false],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Dollar Bill",
    label: "Dollar Bill",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [false, true, false, true, false],
      N: [false, true, true, true, false],
      G: [false, true, false, true, false],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Dollar Sign",
    label: "Dollar Sign",
    otherNames: [],
    pattern: {
      B: [false, true, false, false, true],
      I: [true, false, true, false, true],
      N: [true, true, true, true, true],
      G: [true, false, true, false, true],
      O: [true, false, false, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Dollar Sign (Alternate)",
    label: "Dollar Sign (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, true],
      I: [true, false, true, false, true],
      N: [true, true, true, true, true],
      G: [true, false, true, false, true],
      O: [true, false, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Dominoes",
    label: "Dominoes",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, false, false, false, false],
      N: [true, false, true, false, true],
      G: [false, false, false, false, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: ["I", "G"],
    credit: null,
  },
  {
    value: "Door",
    label: "Door",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, true, true],
      N: [true, true, false, true, true],
      G: [true, true, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: "Eliza",
  },
  {
    value: "Double Bingo (May Include 4 Corners)",
    label: "Double Bingo (May Include 4 Corners)",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [true, true, true, true, true],
      N: [false, false, true, false, false],
      G: [false, false, false, true, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
    winningPatterns: [
      {
        B: [true, false, false, false, true],
        I: [false, false, false, false, false],
        N: [true, true, true, true, true],
        G: [false, false, false, false, false],
        O: [true, false, false, false, true],
      },
      {
        B: [false, false, true, false, false],
        I: [false, false, true, false, false],
        N: [false, false, true, false, false],
        G: [true, true, true, true, true],
        O: [false, false, true, false, false],
      },
      {
        B: [true, false, false, false, true],
        I: [false, false, false, true, false],
        N: [false, false, true, false, false],
        G: [false, true, false, false, false],
        O: [true, false, false, false, true],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, false],
        N: [false, false, true, true, false],
        G: [false, true, false, true, false],
        O: [true, false, false, true, false],
      },
    ],
  },
  {
    value: "Double C",
    label: "Double C",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, false, true, false, false],
      N: [false, false, false, false, false],
      G: [false, false, true, true, true],
      O: [false, false, true, false, true],
    },
    unusedLetters: ["N"],
    credit: "Michelle Langer",
  },
  {
    value: "Double Cross",
    label: "Double Cross",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, false, true, false],
      N: [true, true, false, true, true],
      G: [false, true, false, true, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: "MSDX",
  },
  {
    value: "Double Frame",
    label: "Double Frame",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, false, true, false, false],
      N: [true, true, true, true, true],
      G: [false, false, true, false, true],
      O: [false, false, true, true, true],
    },
    unusedLetters: [],
    credit: "Michelle Langer",
  },
  {
    value: "Double Stamp",
    label: "Double Stamp",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, true, true],
      N: [false, false, false, true, true],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["G", "O"],
    credit: null,
  },
  {
    value: "Dos Bingo",
    label: "Dos Bingo",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, true, true, true, true],
      N: [true, true, true, true, true],
      G: [true, true, true, true, true],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Dot 8",
    label: "Dot 8",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, false, true, false, false],
      N: [true, false, false, false, true],
      G: [false, false, true, false, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Dot 14",
    label: "Dot 14",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [true, true, false, true, true],
      N: [true, true, false, true, true],
      G: [true, true, false, true, true],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Double Bingo",
    label: "Double Bingo",
    otherNames: ["Two Bingos", "2 Bingos"],
    pattern: {
      B: [false, false, true, false, false],
      I: [true, true, true, true, true],
      N: [false, false, true, false, false],
      G: [false, false, true, false, false],
      O: [false, false, true, false, false],
    },
    winningPatterns: [
      {
        B: [true, false, false, true, false],
        I: [false, true, false, true, false],
        N: [false, false, true, true, false],
        G: [false, false, false, true, false],
        O: [false, false, false, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [false, true, false, false, false],
        N: [false, true, false, false, false],
        G: [false, true, false, false, false],
        O: [false, true, false, false, false],
      },
      {
        B: [false, false, true, false, true],
        I: [false, false, true, true, false],
        N: [false, false, true, false, false],
        G: [false, true, true, false, false],
        O: [true, false, true, false, false],
      },
      {
        B: [false, true, false, false, false],
        I: [false, true, false, false, false],
        N: [false, true, false, false, false],
        G: [true, true, true, true, true],
        O: [false, true, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Double Chevron",
    label: "Double Chevron",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, true],
      I: [false, true, false, true, false],
      N: [true, false, true, false, false],
      G: [false, true, false, true, false],
      O: [false, false, true, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Double 6 Pack",
    label: "Double 6 Pack",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, true, true, false, false],
      N: [false, false, false, false, false],
      G: [false, false, true, true, true],
      O: [false, false, true, true, true],
    },
    winningPatterns: [
      {
        B: [true, true, true, false, false],
        I: [true, true, true, false, false],
        N: [false, false, false, false, false],
        G: [false, false, true, true, true],
        O: [false, false, true, true, true],
      },
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [true, true, false, true, true],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
      {
        B: [false, false, true, true, true],
        I: [false, false, true, true, true],
        N: [false, false, false, false, false],
        G: [true, true, true, false, false],
        O: [true, true, true, false, false],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [true, true, false, true, true],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Double L",
    label: "Double L",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, true, true, true, true],
      N: [false, false, false, true, true],
      G: [false, false, false, true, true],
      O: [false, false, false, true, true],
    },
    unusedLetters: [],
    credit: "Craig D Miller",
  },
  {
    value: "Double L (Alternate)",
    label: "Double L (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, false, true],
      N: [true, true, true, false, true],
      G: [false, false, true, false, true],
      O: [false, false, true, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Double Triple Dot",
    label: "Double Triple Dot",
    otherNames: [],
    pattern: {
      B: [false, true, false, false, false],
      I: [false, true, false, false, false],
      N: [true, true, false, false, false],
      G: [true, false, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: [],
    credit: "Alissa Wagoner",
  },
  {
    value: "Double X",
    label: "Double X",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, false],
      I: [false, true, false, false, false],
      N: [true, false, true, false, true],
      G: [false, false, false, true, false],
      O: [false, false, true, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Down",
    label: "Down",
    otherNames: [],
    pattern: {
      B: [false, true, false, false, false],
      I: [true, true, true, false, false],
      N: [true, true, true, true, false],
      G: [true, true, true, false, false],
      O: [false, true, false, false, false],
    },
    unusedLetters: [],
    credit: "Convivial Kendrea",
  },
  {
    value: "Dragonfly",
    label: "Dragonfly",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, false],
      I: [false, true, false, false, false],
      N: [true, true, true, true, true],
      G: [false, true, false, false, false],
      O: [true, false, true, false, false],
    },
    unusedLetters: [],
    credit: "Adrienne",
  },
  {
    value: "Dragonfly (Alternate)",
    label: "Dragonfly (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, false],
      I: [true, true, false, false, false],
      N: [true, true, true, true, true],
      G: [true, true, false, false, false],
      O: [true, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Dragonfly (Alternate 2)",
    label: "Dragonfly (Alternate 2)",
    otherNames: [],
    pattern: {
      B: [false, true, false, true, false],
      I: [false, true, true, false, false],
      N: [true, true, true, true, true],
      G: [false, true, true, false, false],
      O: [false, true, false, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
];
