export const mPatterns = [
  /* --------------------- M -------------------- */
  {
    value: "Mail",
    label: "Mail",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, true, false, false, true],
      N: [true, false, true, false, true],
      G: [true, true, false, false, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: "CJ",
  },
  {
    value: "Make A Basket",
    label: "Make A Basket",
    otherNames: [],
    pattern: {
      B: [false, false, true, true, true],
      I: [false, false, true, true, true],
      N: [true, true, true, true, true],
      G: [false, false, true, true, true],
      O: [false, false, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Maple Leaf",
    label: "Maple Leaf",
    otherNames: [],
    pattern: {
      B: [false, true, false, true, false],
      I: [false, false, true, true, false],
      N: [true, true, true, true, true],
      G: [false, false, true, true, false],
      O: [false, true, false, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Margarita",
    label: "Margarita",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [false, true, false, false, true],
      N: [false, true, true, true, true],
      G: [false, true, false, false, true],
      O: [true, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Mickey Mouse ",
    label: "Mickey Mouse ",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [false, true, true, true, false],
      N: [false, true, false, true, false],
      G: [false, true, true, true, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: [],
    credit: "Veronica Osuna",
  },
  {
    value: "Middle Exe",
    label: "Middle Exe",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, false, false, false, true],
      N: [false, true, false, true, false],
      G: [false, true, true, false, false],
      O: [true, false, false, true, false],
    },
    unusedLetters: ["B"],
    credit: null,
  },
  {
    value: "Midnight",
    label: "Midnight",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, false, false, false, true],
      N: [true, true, true, false, true],
      G: [true, false, false, false, true],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: "Denise S",
  },
  {
    value: "Missing Link",
    label: "Missing Link",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, false, false, true],
      N: [true, false, false, false, true],
      G: [true, false, false, false, true],
      O: [true, true, true, false, true],
    },
    winningPatterns: [
      {
        B: [false, true, true, true, true],
        I: [true, false, false, false, true],
        N: [true, false, false, false, true],
        G: [true, false, false, false, true],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [false, false, false, false, true],
        N: [true, false, false, false, true],
        G: [true, false, false, false, true],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, false, false, false, true],
        N: [false, false, false, false, true],
        G: [true, false, false, false, true],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, false, false, false, true],
        N: [true, false, false, false, true],
        G: [false, false, false, false, true],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, false, false, false, true],
        N: [true, false, false, false, true],
        G: [true, false, false, false, true],
        O: [false, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, false, false, false, true],
        N: [true, false, false, false, true],
        G: [true, false, false, false, true],
        O: [true, false, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, false, false, false, true],
        N: [true, false, false, false, true],
        G: [true, false, false, false, true],
        O: [true, true, false, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, false, false, false, true],
        N: [true, false, false, false, true],
        G: [true, false, false, false, true],
        O: [true, true, true, false, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, false, false, false, true],
        N: [true, false, false, false, true],
        G: [true, false, false, false, true],
        O: [true, true, true, true, false],
      },
      {
        B: [true, true, true, true, true],
        I: [true, false, false, false, true],
        N: [true, false, false, false, true],
        G: [true, false, false, false, false],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, false, false, false, true],
        N: [true, false, false, false, false],
        G: [true, false, false, false, true],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, false, false, false, false],
        N: [true, false, false, false, true],
        G: [true, false, false, false, true],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, false],
        I: [true, false, false, false, true],
        N: [true, false, false, false, true],
        G: [true, false, false, false, true],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, false, true],
        I: [true, false, false, false, true],
        N: [true, false, false, false, true],
        G: [true, false, false, false, true],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, false, true, true],
        I: [true, false, false, false, true],
        N: [true, false, false, false, true],
        G: [true, false, false, false, true],
        O: [true, true, true, true, true],
      },
      {
        B: [true, false, true, true, true],
        I: [true, false, false, false, true],
        N: [true, false, false, false, true],
        G: [true, false, false, false, true],
        O: [true, true, true, true, true],
      },
    ],
    unusedLetters: [],
    credit: "Alissa Wagoner",
  },
  {
    value: "Monkey",
    label: "Monkey",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, true],
      I: [true, true, true, true, false],
      N: [false, false, true, true, true],
      G: [true, false, false, true, false],
      O: [false, true, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Moon",
    label: "Moon",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, false],
      I: [false, false, false, true, true],
      N: [false, false, true, true, true],
      G: [true, true, true, true, true],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Moon (Alternate)",
    label: "Moon (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, true, true, true, true],
      N: [true, false, false, false, true],
      G: [true, false, false, false, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "Moustache",
    label: "Moustache",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, false],
      I: [false, false, true, false, false],
      N: [false, false, true, false, false],
      G: [false, false, true, false, false],
      O: [false, false, false, true, false],
    },
    unusedLetters: ["N"],
    credit: "Tony Spin",
  },
  {
    value: "Mug",
    label: "Mug",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, true, true, true, true],
      N: [true, true, true, true, true],
      G: [false, true, false, true, false],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Muscle Man",
    label: "Muscle Man",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [false, true, true, false, true],
      N: [true, true, true, true, false],
      G: [false, true, true, false, true],
      O: [true, true, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Mushroom",
    label: "Mushroom",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, false],
      I: [false, false, true, true, false],
      N: [false, true, true, true, true],
      G: [false, false, true, true, false],
      O: [false, false, false, true, false],
    },
    unusedLetters: [],
    credit: "Marianne Nissen",
  },
  {
    value: "Music Note (Alternate)",
    label: "Music Note (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, true],
      I: [true, true, true, true, true],
      N: [true, false, false, false, false],
      G: [true, false, false, true, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: "Lindsey J",
  },
  {
    value: "Music Note",
    label: "Music Note",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, true, true],
      N: [true, true, true, true, true],
      G: [false, true, false, false, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: ["B"],
    credit: null,
  },
  {
    value: "My",
    label: "My",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, true, false, false, false],
      N: [false, false, true, true, true],
      G: [false, true, false, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
];
