export const tPatterns = [
  /* --------------------- T -------------------- */
  {
    value: "T",
    label: "T",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, false, false, false],
      N: [false, true, false, true, false],
      G: [false, true, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Teeter Totter",
    label: "Teeter Totter",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [false, true, false, false, false],
      N: [false, false, true, true, true],
      G: [false, false, false, true, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Teeter Totter (Alternate)",
    label: "Teeter Totter (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [false, false, false, true, false],
      N: [false, false, true, true, true],
      G: [false, true, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Teeter Totter (Either Direction)",
    label: "Teeter Totter (Either Direction)",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [false, true, false, false, false],
      N: [false, false, true, true, true],
      G: [false, false, false, true, false],
      O: [false, false, false, false, true],
    },
    winningPatterns: [
      {
        B: [true, false, false, false, false],
        I: [false, true, false, false, false],
        N: [false, false, true, true, true],
        G: [false, false, false, true, false],
        O: [false, false, false, false, true],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, true, false],
        N: [false, false, true, true, true],
        G: [false, true, false, false, false],
        O: [true, false, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Ten Lucky",
    label: "Ten Lucky",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, false, false],
      N: [true, true, false, false, false],
      G: [true, true, false, false, false],
      O: [true, true, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Tent",
    label: "Tent",
    otherNames: [],
    pattern: {
      B: [false, false, true, true, true],
      I: [false, true, true, false, false],
      N: [true, true, false, false, false],
      G: [false, true, true, false, false],
      O: [false, false, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Terno",
    label: "Terno",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [false, false, true, false, false],
      G: [false, false, true, false, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: ["B", "I", "N"],
    credit: null,
    winningPatterns: [
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, true, false],
        G: [false, false, false, true, false],
        O: [false, false, false, true, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, true, true, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, false, true],
        N: [false, false, false, false, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, false, false, false, false],
        G: [true, false, false, false, false],
        O: [true, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, true, true, true, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, true, false, false, false],
        G: [false, true, false, false, false],
        O: [false, true, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, true, false],
        G: [false, false, false, true, false],
        O: [false, false, false, true, false],
      },
    ],
  },
  {
    value: "The 31 Game",
    label: "The 31 Game",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, true],
      I: [true, false, true, false, true],
      N: [true, true, true, true, true],
      G: [false, false, false, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["G"],
    credit: "IVETTE DANZO",
  },
  {
    value: "The Arrow",
    label: "The Arrow",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, true, false, false, false],
      N: [true, false, true, false, false],
      G: [false, false, false, true, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "The Cross",
    label: "The Cross",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, false, false, false],
      N: [true, true, true, true, true],
      G: [false, true, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "The Weird Way ",
    label: "The Weird Way ",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, false, false, false, true],
      N: [true, true, true, true, true],
      G: [false, false, false, false, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "TikTok Bingo",
    label: "TikTok Bingo",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [true, true, true, false, false],
      N: [true, false, true, false, false],
      G: [false, false, true, true, true],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Tipas Classic Bingo",
    label: "Tipas Classic Bingo",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, true, false, true, false],
      N: [false, false, false, false, false],
      G: [false, true, false, true, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: ["N"],
    credit: null,
    winningPatterns: [
      {
        B: [true, false, false, false, true],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, true],
      },
      {
        B: [true, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, false, false, false, true],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, false, false, false, true],
      },
      {
        B: [false, false, false, false, false],
        I: [false, true, false, false, false],
        N: [true, false, false, false, false],
        G: [false, true, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, true, false],
        N: [false, false, false, false, true],
        G: [false, false, false, true, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, true, false, false],
        I: [false, true, false, true, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, true, false, true, false],
        O: [false, false, true, false, false],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, false, false],
        N: [true, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, true],
      },
      {
        B: [true, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, true],
        G: [false, false, false, false, false],
        O: [true, false, false, false, false],
      },
      {
        B: [true, false, false, false, true],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, true, false, false],
      },
      {
        B: [false, false, true, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, false, false, false, true],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, true, false],
        N: [false, true, false, false, false],
        G: [false, false, false, true, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, true, false, true, false],
        N: [false, false, false, false, false],
        G: [false, false, false, true, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, true, false],
        N: [false, false, false, false, false],
        G: [false, true, false, true, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, true, false, false, false],
        N: [false, false, false, true, false],
        G: [false, true, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, true, false, true, false],
        N: [false, false, false, false, false],
        G: [false, true, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [true, false, false, false, true],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, true, false, true, false],
        N: [false, false, false, false, false],
        G: [false, false, true, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, true, false, false],
        N: [false, false, false, false, false],
        G: [false, true, false, true, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, true, false, false, false],
        N: [false, false, false, false, false],
        G: [false, true, false, true, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, true, true, false, false],
        N: [false, true, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, true, true, false],
        N: [false, false, false, true, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, true, false],
        G: [false, false, true, true, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, true, false, false, false],
        G: [false, true, true, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [true, false, false, false, false],
        I: [false, true, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, true, false],
        O: [false, false, false, false, true],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, true, false],
        N: [false, false, false, false, false],
        G: [false, true, false, false, false],
        O: [true, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, true, false, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, true, false, false],
        I: [false, false, true, false, false],
        N: [false, false, false, false, false],
        G: [false, false, true, false, false],
        O: [false, false, true, false, false],
      },
    ],
  },
  {
    value: "To The Right",
    label: "To The Right",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, false, false, false, true],
      N: [false, true, false, true, false],
      G: [false, false, true, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: "Jordon",
  },
  {
    value: "Top",
    label: "Top",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, false],
      I: [false, false, true, true, false],
      N: [true, true, true, true, true],
      G: [false, false, true, true, false],
      O: [false, false, false, true, false],
    },
    unusedLetters: [],
    credit: "Marianne Nissen",
  },
  {
    value: "Top and Bottom",
    label: "Top and Bottom",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, false, false, false, true],
      N: [true, false, false, false, true],
      G: [true, false, false, false, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Top Half",
    label: "Top Half",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, true, true, false, false],
      N: [true, true, true, false, false],
      G: [true, true, true, false, false],
      O: [true, true, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Top Hat",
    label: "Top Hat",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, false],
      I: [true, true, true, true, false],
      N: [true, true, true, true, false],
      G: [true, true, true, true, false],
      O: [false, false, false, true, false],
    },
    unusedLetters: [],
    credit: "Bob Versheck",
  },
  {
    value: "Top Hat (Alternate)",
    label: "Top Hat (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [false, true, true, true, true],
      N: [false, true, true, true, true],
      G: [false, true, true, true, true],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Top Or Bottom Row",
    label: "Top Or Bottom Row",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [false, false, false, false, true],
      N: [false, false, false, false, true],
      G: [false, false, false, false, true],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
    winningPatterns: [
      {
        B: [false, false, false, false, true],
        I: [false, false, false, false, true],
        N: [false, false, false, false, true],
        G: [false, false, false, false, true],
        O: [false, false, false, false, true],
      },
      {
        B: [true, false, false, false, false],
        I: [true, false, false, false, false],
        N: [true, false, false, false, false],
        G: [true, false, false, false, false],
        O: [true, false, false, false, false],
      },
    ],
  },
  {
    value: "Top Row",
    label: "Top Row",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [true, false, false, false, false],
      N: [true, false, false, false, false],
      G: [true, false, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Top or Bottom B-N-O",
    label: "Top or Bottom B-N-O",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [false, false, false, false, false],
      N: [true, false, false, false, false],
      G: [false, false, false, false, false],
      O: [true, false, false, false, false],
    },

    winningPatterns: [
      {
        B: [true, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, false, false, false, false],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, false, false],
        N: [false, false, false, false, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, true],
      },
    ],
    unusedLetters: ["I", "G"],
    credit: null,
  },
  {
    value: "Torch",
    label: "Torch",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, true, false, false],
      N: [true, true, true, true, true],
      G: [false, true, true, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Train Tracks (any two horizontal lines together)",
    label: "Train Tracks (any two horizontal lines together)",
    otherNames: [],
    pattern: {
      B: [false, true, true, false, false],
      I: [false, true, true, false, false],
      N: [false, true, true, false, false],
      G: [false, true, true, false, false],
      O: [false, true, true, false, false],
    },
    winningPatterns: [
      {
        B: [false, true, true, false, false],
        I: [false, true, true, false, false],
        N: [false, true, true, false, false],
        G: [false, true, true, false, false],
        O: [false, true, true, false, false],
      },
      {
        B: [false, false, true, true, false],
        I: [false, false, true, true, false],
        N: [false, false, true, true, false],
        G: [false, false, true, true, false],
        O: [false, false, true, true, false],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, false, false, true, true],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [true, true, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Tree",
    label: "Tree",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, false],
      I: [false, true, true, true, false],
      N: [true, true, true, true, true],
      G: [false, true, true, true, false],
      O: [false, false, false, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Triangle",
    label: "Triangle",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, false],
      I: [false, false, true, true, false],
      N: [false, true, false, true, false],
      G: [false, false, true, true, false],
      O: [false, false, false, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Triangle & Horizontal",
    label: "Triangle & Horizontal",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, true],
      I: [false, true, true, false, true],
      N: [true, false, true, false, true],
      G: [false, true, true, false, true],
      O: [false, false, true, false, true],
    },
    unusedLetters: [],
    credit: "Marianne Nissen",
  },
  {
    value: "Triangle Game",
    label: "Triangle Game",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, true, true, true, false],
      N: [true, true, true, false, false],
      G: [true, true, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Trio",
    label: "Trio",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [false, false, false, false, false],
      N: [true, false, false, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: ["I", "G"],
    credit: null,
  },
  {
    value: "Triple Bingo",
    label: "Triple Bingo",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, true, false],
      N: [false, false, true, true, false],
      G: [false, true, false, true, false],
      O: [true, false, false, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Triple Dot",
    label: "Triple Dot",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [false, false, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["I", "N", "G", "O"],
    credit: null,
    winningPatterns: [
      {
        B: [false, true, true, true, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, true, false, false, false],
        N: [false, true, false, false, false],
        G: [false, true, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, true, true, true],
      },
    ],
  },
  {
    value: "Triple Dot (Alternate)",
    label: "Triple Dot (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, true],
      I: [false, false, false, false, false],
      N: [true, false, true, false, true],
      G: [false, false, false, false, false],
      O: [true, false, true, false, true],
    },
    unusedLetters: ["I", "G"],
    credit: null,
  },
  {
    value: "Tulip",
    label: "Tulip",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, false],
      I: [false, true, false, false, true],
      N: [true, false, false, false, true],
      G: [false, true, false, false, true],
      O: [true, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Turkey",
    label: "Turkey",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [false, true, true, true, false],
      N: [false, false, true, true, true],
      G: [false, false, true, true, false],
      O: [false, true, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Turtle",
    label: "Turtle",
    otherNames: [],
    pattern: {
      B: [false, true, false, false, true],
      I: [false, true, true, true, false],
      N: [true, true, true, true, false],
      G: [false, true, true, true, false],
      O: [false, true, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Turtle (Alternate)",
    label: "Turtle (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, true, true, true, false],
      N: [true, true, false, true, false],
      G: [false, true, true, true, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Two/Dikit",
    label: "Two/Dikit",
    otherNames: [],
    pattern: {
      B: [true, false, false, true, true],
      I: [true, false, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, false, true],
      O: [true, true, false, false, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Two Brackets",
    label: "Two Brackets",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, false, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, false, true],
      O: [false, false, false, true, true],
    },
    winningPatterns: [
      {
        B: [true, true, false, false, false],
        I: [true, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, true],
        O: [false, false, false, true, true],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, false, true],
        N: [false, false, false, false, false],
        G: [true, false, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [true, true, false, false, false],
        I: [true, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, false, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, false, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, true],
        O: [false, false, false, true, true],
      },
      {
        B: [true, true, false, true, true],
        I: [true, false, false, false, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, false, false, false, true],
        O: [true, true, false, true, true],
      },
    ],
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Two Left Feet",
    label: "Two Left Feet",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [true, true, true, true, true],
      N: [false, false, false, false, false],
      G: [false, false, false, false, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["N"],
    credit: "Bob Versheck",
  },
  {
    value: "Two Lines",
    label: "Two Lines",
    otherNames: ["2 Lines"],
    pattern: {
      B: [true, true, false, false, false],
      I: [false, true, false, false, false],
      N: [false, true, true, false, false],
      G: [false, true, false, true, false],
      O: [false, true, false, false, true],
    },
    winningPatterns: [
      {
        B: [true, true, false, false, false],
        I: [false, true, false, false, false],
        N: [false, true, true, false, false],
        G: [false, true, false, true, false],
        O: [false, true, false, false, true],
      },
      {
        B: [false, false, false, true, false],
        I: [true, true, true, true, true],
        N: [false, false, false, true, false],
        G: [false, false, false, true, false],
        O: [false, false, false, true, false],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, true, false],
        N: [false, false, true, false, false],
        G: [false, true, false, false, false],
        O: [true, true, true, true, true],
      },
      {
        B: [false, false, false, false, true],
        I: [true, true, true, true, true],
        N: [false, false, false, false, true],
        G: [false, false, false, false, true],
        O: [false, false, false, false, true],
      },
    ],
    credit: null,
    unusedLetters: [],
  },
  {
    value: "Two Only",
    label: "Two Only",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [true, false, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Two Rows",
    label: "Two Rows",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, false, false],
      N: [true, true, false, false, false],
      G: [true, true, false, false, false],
      O: [true, true, false, false, false],
    },
    winningPatterns: [
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [true, true, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [true, false, true, false, false],
        I: [true, false, true, false, false],
        N: [true, false, true, false, false],
        G: [true, false, true, false, false],
        O: [true, false, true, false, false],
      },
      {
        B: [false, true, false, true, false],
        I: [false, true, false, true, false],
        N: [false, true, false, true, false],
        G: [false, true, false, true, false],
        O: [false, true, false, true, false],
      },
      {
        B: [false, true, true, false, false],
        I: [false, true, true, false, false],
        N: [false, true, true, false, false],
        G: [false, true, true, false, false],
        O: [false, true, true, false, false],
      },
      {
        B: [false, false, true, false, true],
        I: [false, false, true, false, true],
        G: [false, false, true, false, true],
        O: [false, false, true, false, true],
        N: [false, false, true, false, true],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  { value: "Two Stamps Anywhere", label: "Two Stamps Anywhere", otherNames: [], pattern: { B: [false, false, false, false, false], I: [false, false, false, true, true], N: [false, true, true, true, true], G: [false, true, true, false, false], O: [false, false, false, false, false] }, unusedLetters: ["B", "O"], credit: null },
  { value: "Two Stamps Anywhere (Alternate)", label: "Two Stamps Anywhere (Alternate)", otherNames: [], pattern: { B: [false, false, true, true, false], I: [false, false, true, true, false], N: [false, false, true, true, false], G: [false, false, true, true, false], O: [false, false, false, false, false] }, unusedLetters: ["O"], credit: null },
  {
    value: "Two Stamps Any Where",
    label: "Two Stamps Any Where",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, false, false, false],
      N: [true, true, true, true, false],
      G: [false, false, true, true, false],
      O: [false, false, false, false, false],
    },
    winningPatterns: [
      {
        B: [false, false, false, false, false],
        I: [true, true, false, false, false],
        N: [true, true, true, true, false],
        G: [false, false, true, true, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, true, true, false, false],
        G: [false, true, true, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, true, true, false, false],
        I: [false, true, true, false, false],
        N: [false, false, false, false, false],
        G: [false, false, true, true, false],
        O: [false, false, true, true, false],
      },
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
      {
        B: [false, false, true, true, false],
        I: [false, false, true, true, false],
        N: [false, false, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
    ],
    unusedLetters: ["B", "O"],
    credit: "Lynda Hughes",
  },
];
