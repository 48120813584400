export const jPatterns = [
  /* --------------------- J -------------------- */
  {
    value: "Jambalaya (All But One Line)",
    label: "Jambalaya (All But One Line)",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, true, true],
      N: [true, true, true, true, true],
      G: [true, true, true, true, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["B"],
    credit: "CJ",
    winningPatterns: [
      {
        B: [true, true, true, true, true],
        I: [false, false, false, false, false],
        N: [true, true, true, true, true],
        G: [true, true, true, true, true],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, true, true, true, true],
        N: [false, false, false, false, false],
        G: [true, true, true, true, true],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, true, true, true, true],
        N: [true, true, true, true, true],
        G: [false, false, false, false, false],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, true, true, true, true],
        N: [true, true, true, true, true],
        G: [true, true, true, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [false, true, true, true, true],
        I: [false, true, true, true, true],
        N: [false, true, true, true, true],
        G: [false, true, true, true, true],
        O: [false, true, true, true, true],
      },
      {
        B: [true, false, true, true, true],
        I: [true, false, true, true, true],
        N: [true, false, true, true, true],
        G: [true, false, true, true, true],
        O: [true, false, true, true, true],
      },
      {
        B: [true, true, false, true, true],
        I: [true, true, false, true, true],
        N: [true, true, false, true, true],
        G: [true, true, false, true, true],
        O: [true, true, false, true, true],
      },
      {
        B: [true, true, true, false, true],
        I: [true, true, true, false, true],
        N: [true, true, true, false, true],
        G: [true, true, true, false, true],
        O: [true, true, true, false, true],
      },
      {
        B: [true, true, true, true, false],
        I: [true, true, true, true, false],
        N: [true, true, true, true, false],
        G: [true, true, true, true, false],
        O: [true, true, true, true, false],
      },
      {
        B: [false, true, true, true, true],
        I: [true, false, true, true, true],
        N: [true, true, false, true, true],
        G: [true, true, true, false, true],
        O: [true, true, true, true, false],
      },
      {
        B: [true, true, true, true, false],
        I: [true, true, true, false, true],
        N: [true, true, false, true, true],
        G: [true, false, true, true, true],
        O: [false, true, true, true, true],
      },
    ],
  },
  {
    value: "Jamboree",
    label: "Jamboree",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [false, true, true, false, false],
      G: [false, true, true, false, false],
      O: [false, true, true, false, false],
    },
    unusedLetters: ["B", "I"],
    credit: "Alissa Wagoner",
    winningPatterns: [
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, true, true, false, false],
        G: [false, true, true, false, false],
        O: [false, true, true, false, false],
      },
      {
        B: [true, false, false, false, true],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, false, false, false, true],
      },
      {
        B: [false, false, false, false, false],
        I: [false, true, false, true, false],
        N: [false, false, false, false, false],
        G: [false, true, false, true, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, true, false],
        N: [false, false, true, false, false],
        G: [false, true, false, false, false],
        O: [true, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
    ],
  },
  {
    value: "Jamboree On 4 Cards",
    label: "Jamboree On 4 Cards",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, true],
      I: [true, true, false, true, false],
      N: [false, false, true, true, true],
      G: [false, true, false, true, true],
      O: [true, false, false, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
];
