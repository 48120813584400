export const rPatterns = [
  /* --------------------- R -------------------- */
  {
    value: "Rabbit Ear TV",
    label: "Rabbit Ear TV",
    otherNames: [],
    pattern: {
      B: [true, false, true, true, true],
      I: [false, true, true, false, true],
      N: [false, false, true, false, true],
      G: [false, true, true, false, true],
      O: [true, false, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Railroad Tracks",
    label: "Railroad Tracks",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, true, true],
      N: [false, false, false, false, false],
      G: [true, true, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "N", "O"],
    credit: null,
  },
  {
    value: "Rainbow",
    label: "Rainbow",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, true, true, false, false],
      N: [true, true, false, false, false],
      G: [true, true, true, false, false],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: "Patricia Morrison",
  },
  {
    value: "Razzle Dazzle (Any 3 Numbers In Each Row)",
    label: "Razzle Dazzle (Any 3 Numbers In Each Row)",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, true, false, false, true],
      N: [true, false, false, true, false],
      G: [true, false, true, true, true],
      O: [false, true, true, false, true],
    },
    winningPatterns: [
      {
        B: [false, true, true, true, false],
        I: [true, true, false, false, true],
        N: [true, false, false, true, false],
        G: [true, false, true, true, true],
        O: [false, true, true, false, true],
      },
      {
        B: [true, false, true, false, true],
        I: [false, true, true, true, true],
        N: [true, true, false, true, true],
        G: [false, true, true, false, false],
        O: [true, false, false, true, false],
      },
      {
        B: [false, true, false, true, false],
        I: [false, true, true, true, false],
        N: [true, true, false, true, true],
        G: [true, false, true, false, true],
        O: [true, false, true, false, true],
      },
      {
        B: [true, true, true, true, true],
        I: [false, false, true, false, false],
        N: [true, true, false, true, true],
        G: [false, true, false, false, false],
        O: [true, false, true, true, true],
      },
      {
        B: [true, false, true, false, true],
        I: [true, true, true, true, true],
        N: [false, true, false, false, false],
        G: [true, false, true, true, true],
        O: [false, true, false, true, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Rectangle",
    label: "Rectangle",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [false, true, false, true, false],
      N: [false, true, false, true, false],
      G: [false, true, false, true, false],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: "Robin Tatam",
  },
  {
    value: "Reflex Angle",
    label: "Reflex Angle",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, true, false, false, false],
      N: [true, false, false, false, false],
      G: [true, false, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Regular Bingo & Four Corners",
    label: "Regular Bingo & Four Corners",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, false, false, true, false],
      N: [false, false, true, false, false],
      G: [false, true, false, false, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Regular Bingo",
    label: "Regular Bingo",
    otherNames: ["One Line", "1 Line", "Single Line"],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, true, true],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    winningPatterns: [
      {
        B: [true, true, true, true, true],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [true, false, false, false, false],
        I: [false, true, false, false, false],
        N: [false, false, true, false, false],
        G: [false, false, false, true, false],
        O: [false, false, false, false, true],
      },
      {
        B: [false, false, false, true, false],
        I: [false, false, false, true, false],
        N: [false, false, false, true, false],
        G: [false, false, false, true, false],
        O: [false, false, false, true, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, true, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, true, false],
        N: [false, false, true, false, false],
        G: [false, true, false, false, false],
        O: [true, false, false, false, false],
      },
      {
        B: [false, true, false, false, false],
        I: [false, true, false, false, false],
        N: [false, true, false, false, false],
        G: [false, true, false, false, false],
        O: [false, true, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Regular Multi",
    label: "Regular Multi",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, true],
      I: [true, true, false, true, false],
      N: [false, false, true, false, false],
      G: [false, true, false, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: "Denny McKenzie",
  },
  {
    value: "Regular or 4 Corners",
    label: "Regular or 4 Corners",
    otherNames: ["Regular or Four Corners"],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, true, false, false, false],
      N: [false, false, true, false, false],
      G: [false, false, false, true, false],
      O: [true, false, false, false, true],
    },
    winningPatterns: [
      {
        B: [true, false, false, false, false],
        I: [false, true, false, false, false],
        N: [false, false, true, false, false],
        G: [false, false, false, true, false],
        O: [false, false, false, false, true],
      },
      {
        B: [true, false, false, false, true],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, false, false, false, true],
      },
      {
        B: [false, false, false, true, false],
        I: [false, false, false, true, false],
        N: [false, false, false, true, false],
        G: [false, false, false, true, false],
        O: [false, false, false, true, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, true, true, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Reindeer",
    label: "Reindeer",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [false, true, true, true, true],
      N: [false, false, true, false, true],
      G: [false, true, true, true, true],
      O: [true, true, false, false, false],
    },
    unusedLetters: [],
    credit: "Paige T & Mac",
  },
  {
    value: "Reverse Checker Board",
    label: "Reverse Checker Board",
    otherNames: [],
    pattern: {
      B: [false, true, false, true, false],
      I: [true, false, true, false, true],
      N: [false, true, false, true, false],
      G: [true, false, true, false, true],
      O: [false, true, false, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Reverse Letter N",
    label: "Reverse Letter N",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, true, false],
      N: [false, false, true, false, false],
      G: [false, true, false, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Reverse Letter S",
    label: "Reverse Letter S",
    otherNames: [],
    pattern: {
      B: [true, false, true, true, true],
      I: [true, false, true, false, true],
      N: [true, false, true, false, true],
      G: [true, false, true, false, true],
      O: [true, true, true, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Reverse Letter Z",
    label: "Reverse Letter Z",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, true, false, false, true],
      N: [true, false, true, false, true],
      G: [true, false, false, true, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Reverse Number 3",
    label: "Reverse Number 3",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [true, true, true, true, true],
      G: [true, false, true, false, true],
      O: [true, false, true, false, true],
    },
    unusedLetters: ["B", "I"],
    credit: null,
  },
  {
    value: "Right",
    label: "Right",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, true, true, true, true],
      N: [false, true, true, true, false],
      G: [false, false, true, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: "Convivial Kendrea",
  },
  {
    value: "River",
    label: "River",
    otherNames: [],
    pattern: {
      B: [false, false, true, true, false],
      I: [false, true, true, false, false],
      N: [false, false, true, true, false],
      G: [false, false, true, true, false],
      O: [false, true, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Robs Pattern",
    label: "Robs Pattern",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, false],
      I: [false, true, true, false, false],
      N: [false, false, true, false, false],
      G: [false, false, true, true, false],
      O: [false, false, true, false, true],
    },
    unusedLetters: ["N"],
    credit: "Robert Eberly",
  },
  {
    value: "Rocket",
    label: "Rocket",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, true],
      N: [true, true, true, true, true],
      G: [false, false, false, false, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Roller Coaster",
    label: "Roller Coaster",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, true],
      I: [true, false, false, false, false],
      N: [false, true, true, true, false],
      G: [false, false, false, false, true],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Roller Skate",
    label: "Roller Skate",
    otherNames: [],
    pattern: {
      B: [false, false, true, true, false],
      I: [false, false, true, true, true],
      N: [true, true, true, true, false],
      G: [true, true, true, true, true],
      O: [true, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Roman Numeral II",
    label: "Roman Numeral II",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, true, true, true, true],
      N: [true, false, false, false, true],
      G: [true, true, true, true, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
];
