export const lPatterns = [
  /* --------------------- L -------------------- */
  {
    value: "Ladder",
    label: "Ladder",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, true, true],
      N: [false, true, false, true, false],
      G: [true, true, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Lady Bug",
    label: "Lady Bug",
    otherNames: [],
    pattern: {
      B: [true, false, true, true, true],
      I: [false, true, true, true, true],
      N: [true, false, true, true, true],
      G: [false, false, false, false, false],
      O: [true, false, true, false, true],
    },
    unusedLetters: ["G"],
    credit: "Lindsey J",
  },
  {
    value: "Large Frame",
    label: "Large Frame",
    otherNames: ["Outer Border", "Outside Frame"],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, false, false, true],
      N: [true, false, false, false, true],
      G: [true, false, false, false, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Large Kite",
    label: "Large Kite",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, true, true, false, false],
      N: [true, true, true, false, false],
      G: [false, false, false, true, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Large Ladder",
    label: "Large Ladder",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, true, false, true, false],
      N: [false, true, false, true, false],
      G: [false, true, false, true, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Layer Cake",
    label: "Layer Cake",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, true],
      I: [true, false, true, false, true],
      N: [true, false, true, false, true],
      G: [true, false, true, false, true],
      O: [true, false, true, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Leaf",
    label: "Leaf",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, false, false, true, false],
      N: [true, false, true, true, false],
      G: [false, true, true, true, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Lederhosen",
    label: "Lederhosen",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, false],
      I: [true, true, true, true, true],
      N: [false, true, false, true, false],
      G: [true, true, true, true, true],
      O: [false, false, false, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Left",
    label: "Left",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, true, false, false],
      N: [false, true, true, true, false],
      G: [true, true, true, true, true],
      O: [false, true, true, true, false],
    },
    unusedLetters: ["B"],
    credit: "Convivial Kendrea",
  },
  {
    value: "Letter A",
    label: "Letter A",
    otherNames: [],
    pattern: {
      B: [false, false, true, true, true],
      I: [false, true, true, false, false],
      N: [true, false, true, false, false],
      G: [false, true, true, false, false],
      O: [false, false, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter A (Alternate)",
    label: "Letter A (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, false, true, true, true],
      I: [false, true, false, true, false],
      N: [true, false, false, true, false],
      G: [false, true, false, true, false],
      O: [false, false, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter A (Alternate 2)",
    label: "Letter A (Alternate 2)",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, false],
      N: [true, false, true, false, false],
      G: [true, false, true, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter A (Alternate 3)",
    label: "Letter A (Alternate 3)",
    otherNames: ["Power A"],
    pattern: {
      B: [false, true, true, true, true],
      I: [true, false, true, false, false],
      N: [true, false, true, false, false],
      G: [true, false, true, false, false],
      O: [false, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter A (Alternate 4)",
    label: "Letter A (Alternate 4)",
    otherNames: ["A"],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, true, true],
      N: [true, false, true, false, false],
      G: [true, true, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Letter B",
    label: "Letter B",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, true],
      N: [true, false, true, false, true],
      G: [false, true, false, true, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "Letter B (Alternate)",
    label: "Letter B (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, true],
      N: [true, false, true, false, true],
      G: [true, false, true, false, true],
      O: [true, true, false, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter B (Alternate 2)",
    label: "Letter B (Alternate 2)",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, true],
      N: [true, false, true, false, true],
      G: [true, false, true, false, true],
      O: [false, true, false, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter B (Alternate 3)",
    label: "Letter B (Alternate 3)",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, true],
      N: [true, false, true, false, true],
      G: [true, false, true, false, true],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter C",
    label: "Letter C",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, false, false, false, true],
      N: [true, false, false, false, true],
      G: [true, false, false, false, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "Letter C (Alternate)",
    label: "Letter C (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, false, false, true],
      N: [true, false, false, false, true],
      G: [true, false, false, false, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter D",
    label: "Letter D",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, false, false, true],
      N: [true, false, false, false, true],
      G: [false, true, true, true, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "Letter D (Alternate)",
    label: "Letter D (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, false, false, true],
      N: [true, false, false, false, true],
      G: [true, false, false, false, true],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter E",
    label: "Letter E",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, true],
      N: [true, false, true, false, true],
      G: [true, false, false, false, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "Letter E (Alternate)",
    label: "Letter E (Alternate)",
    otherNames: ["Enhanced"],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, true],
      N: [true, false, true, false, true],
      G: [true, false, true, false, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter E (Alternate 2)",
    label: "E (Alternate 2)",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, true],
      N: [true, false, false, false, true],
      G: [true, false, true, false, true],
      O: [true, false, true, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter E (Alternate 3)",
    label: "Letter E (Alternate 3)",
    otherNames: ["E"],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, true],
      N: [true, false, false, false, true],
      G: [true, false, false, false, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter F",
    label: "Letter F",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, false],
      N: [true, false, true, false, false],
      G: [true, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "Letter F (Alternate)",
    label: "Letter F (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, false],
      N: [true, false, true, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["G", "O"],
    credit: "Frank",
  },
  {
    value: "Letter F (Alternate 2)",
    label: "Letter F (Alternate 2)",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, false],
      N: [true, false, false, false, false],
      G: [true, false, true, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter G",
    label: "Letter G",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, false, false, false, true],
      N: [true, false, true, false, true],
      G: [true, false, true, false, true],
      O: [false, false, true, true, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "Letter H",
    label: "Letter H",
    otherNames: ["Power H"],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, true, false, false],
      N: [false, false, true, false, false],
      G: [false, false, true, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Letter H (Alternate)",
    label: "Letter H (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, true, true],
      N: [false, false, true, false, false],
      G: [true, true, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "N", "O"],
    credit: "Jessica",
  },
  {
    value: "Letter I",
    label: "Letter I",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, false, false, false, true],
      N: [true, true, true, true, true],
      G: [true, false, false, false, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Letter I (Alternate)",
    label: "Letter I (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, false, false, false, true],
      N: [true, true, true, true, true],
      G: [true, false, false, false, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter J",
    label: "Letter J",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, true],
      I: [true, false, false, false, true],
      N: [true, true, true, true, true],
      G: [true, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "Letter J (Alternate)",
    label: "Letter J (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, false, false, true, true],
      I: [true, false, false, false, true],
      N: [true, true, true, true, true],
      G: [true, false, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter K",
    label: "Letter K",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, true, false, false],
      N: [false, true, false, true, false],
      G: [true, false, false, false, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "Letter K (Alternate)",
    label: "Letter K (Alternate)",
    otherNames: ["K.E. K"],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, true, false, false],
      N: [false, false, true, false, false],
      G: [false, true, false, true, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Letter K (Alternate 2)",
    label: "Letter K (Alternate 2)",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, true, true],
      N: [false, false, true, false, false],
      G: [false, true, false, true, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: ["B", "N"],
    credit: "Louise Durgin",
  },
  {
    value: "Letter K (Alternate 3)",
    label: "Letter K (Alternate 3)",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [true, true, true, true, true],
      G: [false, true, false, true, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: ["B", "I"],
    credit: null,
  },
  {
    value: "Letter L",
    label: "Letter L",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, false, true],
      N: [false, false, false, false, true],
      G: [false, false, false, false, true],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter L (Alternate)",
    label: "Letter L (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, false, true],
      N: [false, false, false, false, true],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["G", "O"],
    credit: "Ruth ONeal",
  },
  {
    value: "Letter M",
    label: "Letter M",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, true, false, false, false],
      N: [false, false, true, false, false],
      G: [false, true, false, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Letter M (Alternate)",
    label: "Letter M (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, true, false, false, false],
      N: [false, false, true, true, false],
      G: [false, true, false, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter N",
    label: "Letter N",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, true, false, false, false],
      N: [false, false, true, false, false],
      G: [false, false, false, true, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Letter O",
    label: "Letter O",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, false, false, false, true],
      N: [true, false, false, false, true],
      G: [true, false, false, false, true],
      O: [false, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter P",
    label: "Letter P",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, false],
      N: [true, false, true, false, false],
      G: [true, true, true, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "Letter P (Alternate)",
    label: "Letter P (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, false],
      N: [true, false, true, false, false],
      G: [true, false, true, false, false],
      O: [true, true, true, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "Letter Q",
    label: "Letter Q",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, false],
      I: [true, false, false, false, true],
      N: [true, false, true, false, true],
      G: [true, false, false, true, false],
      O: [false, true, true, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter R",
    label: "Letter R",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, false],
      N: [true, false, true, true, false],
      G: [false, true, false, false, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
  },
  {
    value: "Letter R (Alternate)",
    label: "Letter R (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, false, false, false],
      N: [true, false, true, false, false],
      G: [true, true, false, true, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter S",
    label: "Letter S",
    otherNames: [],
    pattern: {
      B: [false, true, false, false, true],
      I: [true, false, true, false, true],
      N: [true, false, true, false, true],
      G: [true, false, true, false, true],
      O: [true, false, false, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter S (Alternate)",
    label: "Letter S (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, true],
      I: [true, false, true, false, true],
      N: [true, false, true, false, true],
      G: [true, false, true, false, true],
      O: [true, false, true, true, true],
    },
    unusedLetters: [],
    credit: "JPIA",
  },

  {
    value: "Letter S (Alternate 2)",
    label: "Letter S (Alternate 2)",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, false, true],
      N: [true, false, true, false, true],
      G: [true, false, true, true, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Letter T",
    label: "Letter T",
    otherNames: ["Pattern T"],
    pattern: {
      B: [true, false, false, false, false],
      I: [true, false, false, false, false],
      N: [true, true, true, true, true],
      G: [true, false, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter U",
    label: "Letter U",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, false],
      I: [false, false, false, false, true],
      N: [false, false, false, false, true],
      G: [false, false, false, false, true],
      O: [true, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter U (Alternate)",
    label: "Letter U (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, false, true],
      N: [false, false, false, false, true],
      G: [false, false, false, false, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter V",
    label: "Letter V",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [false, false, false, true, false],
      N: [false, false, false, false, true],
      G: [false, false, false, true, false],
      O: [true, true, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter W",
    label: "Letter W",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, false],
      I: [false, false, false, false, true],
      N: [false, false, true, true, false],
      G: [false, false, false, false, true],
      O: [true, true, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter W (Alternate)",
    label: "Letter W (Alternate)",
    otherNames: ["Hard W, W Pattern"],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, true, false],
      N: [false, false, true, false, false],
      G: [false, false, false, true, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Letter W (Alternate 2)",
    label: "Letter W (Alternate 2)",
    otherNames: ["W", "Letter W"],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, true, false],
      N: [false, true, true, false, false],
      G: [false, false, false, true, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter X",
    label: "Letter X",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, true, false, true, false],
      N: [false, false, true, false, false],
      G: [false, true, false, true, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Letter X and Frame",
    label: "Letter X and Frame",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, true, false, true, true],
      N: [true, false, true, false, true],
      G: [true, true, false, true, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter X and N Row",
    label: "Letter X and N Row",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, true, false, true, false],
      N: [true, true, true, true, true],
      G: [false, true, false, true, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter X One Away",
    label: "Letter X One Away",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [false, true, false, true, false],
      N: [false, false, true, false, false],
      G: [false, true, false, true, false],
      O: [true, false, false, false, true],
    },
    winningPatterns: [
      {
        B: [false, false, false, false, true],
        I: [false, true, false, true, false],
        N: [false, false, true, false, false],
        G: [false, true, false, true, false],
        O: [true, false, false, false, true],
      },
      {
        B: [true, false, false, false, true],
        I: [false, false, false, true, false],
        N: [false, false, true, false, false],
        G: [false, true, false, true, false],
        O: [true, false, false, false, true],
      },
      {
        B: [true, false, false, false, true],
        I: [false, true, false, true, false],
        N: [false, false, true, false, false],
        G: [false, true, false, false, false],
        O: [true, false, false, false, true],
      },
      {
        B: [true, false, false, false, true],
        I: [false, true, false, true, false],
        N: [false, false, true, false, false],
        G: [false, true, false, true, false],
        O: [true, false, false, false, false],
      },
      {
        B: [true, false, false, false, true],
        I: [false, true, false, true, false],
        N: [false, false, true, false, false],
        G: [false, true, false, true, false],
        O: [false, false, false, false, true],
      },
      {
        B: [true, false, false, false, true],
        I: [false, true, false, true, false],
        N: [false, false, true, false, false],
        G: [false, false, false, true, false],
        O: [true, false, false, false, true],
      },
      {
        B: [true, false, false, false, true],
        I: [false, true, false, false, false],
        N: [false, false, true, false, false],
        G: [false, true, false, true, false],
        O: [true, false, false, false, true],
      },
      {
        B: [true, false, false, false, false],
        I: [false, true, false, true, false],
        N: [false, false, true, false, false],
        G: [false, true, false, true, false],
        O: [true, false, false, false, true],
      },
    ],
    unusedLetters: ["N"],
    credt: "Stacy Miller",
  },
  {
    value: "Letter Y",
    label: "Letter Y",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [false, true, false, false, false],
      N: [false, false, true, true, true],
      G: [false, true, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Letter Z",
    label: "Letter Z",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, false, false, true, true],
      N: [true, false, true, false, true],
      G: [true, true, false, false, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Light Bulb",
    label: "Light Bulb",
    otherNames: [],
    pattern: {
      B: [false, true, true, false, false],
      I: [true, false, false, true, true],
      N: [true, false, true, true, true],
      G: [true, false, false, true, true],
      O: [false, true, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Line, 4 Corners or a Stamp",
    label: "Line, 4 Corners or a Stamp",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, false, false, true, false],
      N: [false, false, true, false, false],
      G: [true, true, false, false, false],
      O: [true, true, false, false, true],
    },
    winningPatterns: [
      {
        B: [true, false, false, false, false],
        I: [false, true, false, false, false],
        N: [false, false, true, false, false],
        G: [false, false, false, true, false],
        O: [false, false, false, false, true],
      },
      {
        B: [true, false, false, false, true],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, false, false, false, true],
      },
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, true, false],
        I: [false, false, false, true, false],
        N: [false, false, false, true, false],
        G: [false, false, false, true, false],
        O: [false, false, false, true, false],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [true, false, false, false, true],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, false, false, false, true],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [false, false, true, false, false],
        I: [false, false, true, false, false],
        N: [false, false, true, false, false],
        G: [false, false, true, false, false],
        O: [false, false, true, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Line & 4 Corners (Anywhere)",
    label: "Line & 4 Corners (Anywhere)",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [true, true, true, true, true],
    },
    winningPatterns: [
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, true, true, true, true],
      },
      {
        B: [false, true, false, true, true],
        I: [false, true, false, true, true],
        N: [false, true, false, false, false],
        G: [false, true, false, false, false],
        O: [false, true, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
      {
        B: [false, false, false, true, false],
        I: [false, false, false, true, false],
        N: [false, false, false, true, false],
        G: [true, true, false, true, false],
        O: [true, true, false, true, false],
      },
    ],
    unusedLetters: ["N", "G"],
    credit: "Myrna",
  },
  {
    value: "Line Collection",
    label: "Line Collection",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, true],
      I: [false, false, true, true, false],
      N: [true, true, true, true, true],
      G: [false, true, true, false, false],
      O: [true, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Little Brown Jug",
    label: "Little Brown Jug",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [false, false, false, true, true],
      G: [false, false, true, true, true],
      O: [false, false, false, true, true],
    },
    unusedLetters: ["B", "I"],
    credit: null,
  },
  {
    value: "Little Cross",
    label: "Little Cross",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, false, false, false],
      N: [true, true, true, true, false],
      G: [false, true, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
    winningPatterns: [
      {
        B: [false, true, false, false, false],
        I: [true, true, true, true, false],
        N: [false, true, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, true, false, false, false],
        G: [true, true, true, true, false],
        O: [false, true, false, false, false],
      },
      {
        B: [false, false, true, false, false],
        I: [false, true, true, true, true],
        N: [false, false, true, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, true, false, false],
        G: [false, true, true, true, true],
        O: [false, false, true, false, false],
      },
    ],
  },
  {
    value: "Lobster",
    label: "Lobster",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, false, true],
      N: [false, false, true, true, true],
      G: [true, true, false, false, true],
      O: [true, true, false, false, false],
    },
    unusedLetters: [],
    credit: "Louise Durgin",
  },
  {
    value: "Long Horn",
    label: "Long Horn",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [false, false, true, true, false],
      N: [false, false, true, true, true],
      G: [false, false, true, true, false],
      O: [true, true, false, false, false],
    },
    unusedLetters: [],
    credit: "Denise Silnes",
  },
  {
    value: "Loop The Loop (Short)",
    label: "Loop The Loop (Short)",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [false, false, true, false, true],
      N: [false, true, false, true, false],
      G: [false, false, true, false, true],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: "CJ",
  },
  {
    value: "Loop The Loop (Tall)",
    label: "Loop The Loop (Tall)",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [false, true, true, false, true],
      N: [true, false, false, true, false],
      G: [false, true, true, false, true],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: "CJ",
  },
  {
    value: "Love Letter",
    label: "Love Letter",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, false, true],
      N: [false, false, false, false, true],
      G: [true, true, false, false, true],
      O: [true, true, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Love You ",
    label: "Love You ",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, false, true, false, true],
      N: [false, true, false, true, false],
      G: [true, false, true, false, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Lucky 5",
    label: "Lucky 5",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, false, false, false, false],
      N: [true, true, false, false, true],
      G: [false, false, false, false, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: ["I", "G"],
    credit: null,
  },
  {
    value: "Lucky 7",
    label: "Lucky 7",
    otherNames: ["Seven One Direction"],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, false, false, true, false],
      N: [true, false, true, false, false],
      G: [true, true, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: [],
    credit: "Carrie Negron",
  },
  {
    value: "Lucky 7 (Alternate)",
    label: "Lucky 7 (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, false, false, false],
      N: [true, true, false, true, false],
      G: [true, true, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Lucky 7 (Alternate 2)",
    label: "Lucky 7 (Alternate 2)",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [true, false, false, false, false],
      G: [true, false, false, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["B", "I"],
    credit: null,
  },
  {
    value: "Lucky Frame",
    label: "Lucky Frame",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, false, false, true],
      N: [true, false, true, false, false],
      G: [true, false, false, false, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: "MSDX",
    winningPatterns: [
      {
        B: [false, false, false, false, false],
        I: [false, true, true, true, false],
        N: [false, true, true, false, false],
        G: [false, true, true, true, false],
        O: [false, false, false, false, false],
      },
    ],
  },
  {
    value: "Lucky Frame (Alternate)",
    label: "Lucky Frame (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, false, false, true],
      N: [true, false, false, false, true],
      G: [true, false, false, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
    winningPatterns: [
      {
        B: [false, false, false, false, false],
        I: [false, true, true, true, false],
        N: [false, true, false, true, false],
        G: [false, true, true, false, false],
        O: [false, false, false, false, false],
      },
    ],
  },
  {
    value: "Lucky Star",
    label: "Lucky Star",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, true, false, false],
      N: [false, true, false, true, false],
      G: [false, false, true, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
];
