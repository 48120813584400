export const aPatterns = [
  /* --------------------- A -------------------- */
  {
    value: "Acorn",
    label: "Acorn",
    otherNames: [],
    pattern: {
      B: [false, true, true, false, false],
      I: [false, true, true, true, false],
      N: [true, true, true, true, true],
      G: [false, true, true, true, false],
      O: [false, true, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Add & Subtract",
    label: "Add & Subtract",
    otherNames: ["Add and Subtract"],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, false, false, true],
      N: [true, true, true, false, true],
      G: [false, true, false, false, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Airplane",
    label: "Airplane",
    otherNames: ["Aeroplane", "Plane"],
    pattern: {
      B: [false, true, true, true, false],
      I: [false, false, true, false, false],
      N: [false, false, true, false, false],
      G: [true, true, true, true, true],
      O: [false, false, true, false, false],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Airplane (Alternate)",
    label: "Airplane (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, true, false, false, true],
      N: [true, true, true, true, true],
      G: [false, true, false, false, true],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: "Bob Versheck",
  },
  {
    value: "Among Us",
    label: "Among Us",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, false, true, true, true],
      N: [true, false, true, true, false],
      G: [true, true, true, true, true],
      O: [false, false, true, true, false],
    },
    unusedLetters: ["B"],
    credit: "Jer",
  },
  {
    value: "Anchor",
    label: "Anchor",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, true],
      I: [true, false, false, false, true],
      N: [true, true, true, true, true],
      G: [true, false, false, false, true],
      O: [false, false, false, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Anchor (Alternate)",
    label: "Anchor (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [true, false, false, true, false],
      N: [true, true, true, true, true],
      G: [true, false, false, true, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Angel Wings",
    label: "Angel Wings",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, false],
      I: [false, true, false, true, false],
      N: [false, false, true, false, false],
      G: [false, true, false, true, false],
      O: [true, true, true, true, false],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Antler",
    label: "Antler",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [false, false, true, false, false],
      N: [false, false, false, true, true],
      G: [false, false, true, false, false],
      O: [true, true, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Any 3 Tetrominoes",
    label: "Any 3 Tetrominoes",
    otherNames: [],
    pattern: {
      B: [false, false, true, true, true],
      I: [true, true, false, false, true],
      N: [false, true, true, false, false],
      G: [false, false, false, false, false],
      O: [false, true, true, true, true],
    },
    unusedLetters: ["G"],
    credit: null,
    winningPatterns: [
      {
        B: [true, false, false, true, true],
        I: [true, false, false, false, true],
        N: [true, false, true, false, true],
        G: [true, false, true, true, false],
        O: [false, false, false, true, false],
      },
      {
        B: [true, true, true, false, true],
        I: [true, false, false, false, true],
        N: [false, true, false, false, true],
        G: [true, true, false, false, true],
        O: [true, false, false, false, false],
      },
    ],
  },
  {
    value: "Any 5 Straight Line Horizontal Or Vertical Except Free Space",
    label: "Any 5 Straight Line Horizontal Or Vertical Except Free Space",
    otherNames: ["Any Bingo - No Free Space", "Regular Bingo - No Free Space", "Hardway"],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, false, false, false],
      N: [true, false, false, false, false],
      G: [true, false, false, false, false],
      O: [true, false, false, false, false],
    },
    winningPatterns: [
      {
        B: [true, true, true, true, true],
        I: [false, true, false, true, true],
        N: [false, true, false, true, true],
        G: [true, true, true, true, true],
        O: [false, true, false, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, true, true, true, true],
        N: [true, false, false, true, false],
        G: [true, true, true, true, true],
        O: [true, false, false, true, false],
      },
      {
        B: [false, true, false, true, false],
        I: [true, true, true, true, true],
        N: [false, true, false, true, false],
        G: [true, true, true, true, true],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, false, true, true],
        I: [true, true, false, true, true],
        N: [true, true, false, true, true],
        G: [true, true, true, true, true],
        O: [true, true, false, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, true, true, true, true],
        N: [false, true, false, false, false],
        G: [true, true, true, true, true],
        O: [true, true, true, true, true],
      },
    ],
    unusedLetters: [],
    credit: "Charmagne Ilustrisimo",
  },
  {
    value: "Any Diagonal Line",
    label: "Any Diagonal Line",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [false, true, false, false, false],
      N: [false, false, true, false, false],
      G: [false, false, false, true, false],
      O: [false, false, false, false, true],
    },
    winningPatterns: [
      {
        B: [true, false, false, false, false],
        I: [false, true, false, false, false],
        N: [false, false, true, false, false],
        G: [false, false, false, true, false],
        O: [false, false, false, false, true],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, true, false],
        N: [false, false, true, false, false],
        G: [false, true, false, false, false],
        O: [true, false, false, false, false],
      },
    ],
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Any Double Vertical And Horizontal Lines",
    label: "Any Double Vertical And Horizontal Lines",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, true, true, true, true],
      N: [true, true, false, false, false],
      G: [true, true, false, false, false],
      O: [true, true, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Any 5 Around The Corner",
    label: "Any 5 Around The Corner",
    otherNames: ["Any Five Around The Corner", "Five Around The Corner", "5 Around The Corner Anywhere"],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, false, false, false, false],
      N: [true, false, false, false, false],
      G: [false, false, false, false, true],
      O: [false, true, true, true, true],
    },
    winningPatterns: [
      {
        B: [true, true, true, false, false],
        I: [true, false, false, false, false],
        N: [true, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [true, true, false, false, false],
        I: [true, false, false, false, false],
        N: [true, false, false, false, false],
        G: [true, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, false, false, false, false],
        N: [true, false, false, false, false],
        G: [true, false, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, false, false, false, false],
        G: [true, false, false, false, false],
        O: [true, true, true, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, false, false, false, false],
        O: [true, true, true, true, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, true],
        O: [false, true, true, true, true],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, true],
        G: [false, false, false, false, true],
        O: [false, false, true, true, true],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, true],
        N: [false, false, false, false, true],
        G: [false, false, false, false, true],
        O: [false, false, false, true, true],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, false, true],
        N: [false, false, false, false, true],
        G: [false, false, false, false, true],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, true, true, true],
        I: [false, false, false, false, true],
        N: [false, false, false, false, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, true, true, true, true],
        I: [false, false, false, false, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [true, true, true, true, false],
        I: [true, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Any Horizontal Line",
    label: "Any Horizontal Line",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, false, true, false, false],
      N: [false, false, true, false, false],
      G: [false, false, true, false, false],
      O: [false, false, true, false, false],
    },
    winningPatterns: [
      {
        B: [true, false, false, false, false],
        I: [true, false, false, false, false],
        N: [true, false, false, false, false],
        G: [true, false, false, false, false],
        O: [true, false, false, false, false],
      },
      {
        B: [false, true, false, false, false],
        I: [false, true, false, false, false],
        N: [false, true, false, false, false],
        G: [false, true, false, false, false],
        O: [false, true, false, false, false],
      },
      {
        B: [false, false, true, false, false],
        I: [false, false, true, false, false],
        N: [false, false, true, false, false],
        G: [false, false, true, false, false],
        O: [false, false, true, false, false],
      },
      {
        B: [false, false, false, true, false],
        I: [false, false, false, true, false],
        N: [false, false, false, true, false],
        G: [false, false, false, true, false],
        O: [false, false, false, true, false],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, false, true],
        N: [false, false, false, false, true],
        G: [false, false, false, false, true],
        O: [false, false, false, false, true],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Any Line",
    label: "Any Line",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [true, true, true, true, true],
    },
    winningPatterns: [
      {
        B: [true, false, false, false, false],
        I: [true, false, false, false, false],
        N: [true, false, false, false, false],
        G: [true, false, false, false, false],
        O: [true, false, false, false, false],
      },
      {
        B: [false, false, true, false, false],
        I: [false, false, true, false, false],
        N: [false, false, true, false, false],
        G: [false, false, true, false, false],
        O: [false, false, true, false, false],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, false, true],
        N: [false, false, false, false, true],
        G: [false, false, false, false, true],
        O: [false, false, false, false, true],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, true, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [true, false, false, false, false],
        I: [false, true, false, false, false],
        N: [false, false, true, false, false],
        G: [false, false, false, true, false],
        O: [false, false, false, false, true],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Any Line (Alternate)",
    label: "Any Line (Alternate)",
    otherNames: [],
    pattern: { B: [false, false, false, false, true], I: [false, false, false, true, false], N: [false, false, false, false, false], G: [false, true, false, false, false], O: [true, false, false, false, false] },
    unusedLetters: ["N"],
    credit: "Adu",
    winningPatterns: [
      { B: [true, true, true, true, true], I: [false, false, false, false, false], N: [false, false, false, false, false], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [true, true, true, true, true], N: [false, false, false, false, false], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [true, true, false, true, true], G: [false, false, false, false, false], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [false, false, false, false, false], G: [true, true, true, true, true], O: [false, false, false, false, false] },
      { B: [false, false, false, false, false], I: [false, false, false, false, false], N: [false, false, false, false, false], G: [false, false, false, false, false], O: [true, true, true, true, true] },
      { B: [true, false, false, false, false], I: [false, true, false, false, false], N: [false, false, false, false, false], G: [false, false, false, true, false], O: [false, false, false, false, true] },
    ],
  },
  {
    value: "Any Outside Line",
    label: "Any Outside Line",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [true, true, true, true, true],
    },
    winningPatterns: [
      {
        B: [true, true, true, true, true],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [true, false, false, false, false],
        I: [true, false, false, false, false],
        N: [true, false, false, false, false],
        G: [true, false, false, false, false],
        O: [true, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, true, true, true, true],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, false, true],
        N: [false, false, false, false, true],
        G: [false, false, false, false, true],
        O: [false, false, false, false, true],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Any 9 Numbers",
    label: "Any 9 Numbers",
    otherNames: [],
    pattern: {
      B: [true, false, false, true, false],
      I: [false, false, true, false, true],
      N: [false, true, false, true, false],
      G: [true, false, false, false, true],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
    winningPatterns: [
      {
        B: [true, false, false, true, true],
        I: [false, false, false, false, false],
        N: [false, true, false, true, false],
        G: [false, true, false, false, true],
        O: [true, false, true, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, true, true, false],
        N: [true, false, false, true, true],
        G: [false, false, true, false, true],
        O: [false, false, true, false, true],
      },
      {
        B: [false, false, true, true, false],
        I: [true, true, true, true, true],
        N: [false, true, false, true, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [true, false, false, false, false],
        I: [false, true, false, true, false],
        N: [false, false, false, true, false],
        G: [false, true, true, true, false],
        O: [true, false, false, false, true],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, true, false],
        N: [false, false, false, true, false],
        G: [true, true, true, true, false],
        O: [true, true, true, false, false],
      },
      {
        B: [true, false, false, false, false],
        I: [true, true, false, false, true],
        N: [false, false, false, false, false],
        G: [true, false, true, true, true],
        O: [false, false, true, false, false],
      },
      {
        B: [true, false, false, false, true],
        I: [false, false, true, false, false],
        N: [true, false, false, false, true],
        G: [false, true, false, true, false],
        O: [true, false, false, false, true],
      },
      {
        B: [true, false, false, true, false],
        I: [true, false, true, false, false],
        N: [true, false, false, false, false],
        G: [true, false, false, false, true],
        O: [true, false, true, false, false],
      },
    ],
  },
  {
    value: "Any Square",
    label: "Any Square",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, true, true],
      N: [false, false, false, true, true],
      G: [false, true, true, false, false],
      O: [false, true, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Any Straight Line Not Diagonal",
    label: "Any Straight Line Not Diagonal",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [true, true, true, true, true],
      N: [true, false, false, false, false],
      G: [true, false, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Any Ten Numbers",
    label: "Any Ten Numbers",
    otherNames: ["Any 10 Numbers"],
    pattern: {
      B: [false, true, false, true, false],
      I: [true, true, false, true, false],
      N: [false, false, false, false, true],
      G: [true, true, false, true, false],
      O: [true, false, false, false, false],
    },
    winningPatterns: [
      {
        B: [true, true, false, false, true],
        I: [true, false, false, false, true],
        N: [false, true, false, false, false],
        G: [false, true, true, false, false],
        O: [false, true, false, true, false],
      },
      {
        B: [false, true, false, true, false],
        I: [true, false, true, false, true],
        N: [false, false, true, false, false],
        G: [false, true, false, true, false],
        O: [true, false, true, false, false],
      },
      {
        B: [true, false, true, false, true],
        I: [false, false, false, true, false],
        N: [true, false, true, false, true],
        G: [false, false, false, false, false],
        O: [true, false, true, false, true],
      },
      {
        B: [false, true, false, true, false],
        I: [true, false, true, false, true],
        N: [false, false, false, false, false],
        G: [true, false, true, false, true],
        O: [false, true, false, true, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Any Ten Spots, No Free Space)",
    label: "Any Ten Spots, No Free Space",
    otherNames: ["Any 10 Spots, No Free Space"],
    pattern: {
      B: [false, false, true, false, true],
      I: [true, false, false, true, false],
      N: [true, true, false, false, false],
      G: [false, false, true, true, false],
      O: [false, false, false, true, true],
    },
    unusedLetters: [],
    credit: "Alissa Wagoner",
  },
  {
    value: "Any Three Lines",
    label: "Any Three Lines",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, true, false, false, false],
      N: [true, false, true, false, false],
      G: [true, false, false, true, false],
      O: [true, false, false, false, true],
    },
    winningPatterns: [
      {
        B: [true, true, true, true, true],
        I: [true, true, false, false, false],
        N: [true, false, true, false, false],
        G: [true, false, false, true, false],
        O: [true, false, false, false, true],
      },
      {
        B: [false, false, true, false, true],
        I: [true, true, true, true, true],
        N: [false, false, true, false, false],
        G: [false, true, true, false, false],
        O: [true, false, true, false, false],
      },
      {
        B: [false, true, false, true, false],
        I: [false, true, false, true, false],
        N: [true, true, true, true, true],
        G: [false, true, false, true, false],
        O: [false, true, false, true, false],
      },
      {
        B: [false, false, false, true, true],
        I: [true, true, true, true, true],
        N: [false, false, true, true, false],
        G: [false, true, false, true, false],
        O: [true, false, false, true, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Any Three Lines (Alternate)",
    label: "Any Three Lines (Alternate)",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, false],
      I: [true, false, true, false, false],
      N: [true, true, true, true, true],
      G: [true, false, true, false, false],
      O: [true, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Any Two Lines",
    label: "Any Two Lines",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, false, true, false],
      N: [false, false, false, true, false],
      G: [false, false, false, true, false],
      O: [false, false, false, true, false],
    },
    winningPatterns: [
      {
        B: [true, true, true, true, true],
        I: [false, false, true, false, false],
        N: [false, false, true, false, false],
        G: [false, false, true, false, false],
        O: [false, false, true, false, false],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, true, true],
        N: [false, false, true, false, true],
        G: [false, true, false, false, true],
        O: [true, false, false, false, true],
      },
      {
        B: [false, true, false, false, false],
        I: [false, true, false, false, false],
        N: [false, true, false, false, false],
        G: [true, true, true, true, true],
        O: [false, true, false, false, false],
      },
      {
        B: [false, false, true, false, false],
        I: [false, false, true, false, false],
        N: [true, true, true, true, true],
        G: [false, false, true, false, false],
        O: [false, false, true, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Any Two Horizontal Lines",
    label: "Any Two Horizontal Lines",
    otherNames: [],
    pattern: {
      B: [true, false, false, true, false],
      I: [true, false, false, true, false],
      N: [true, false, false, true, false],
      G: [true, false, false, true, false],
      O: [true, false, false, true, false],
    },
    winningPatterns: [
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [true, true, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [true, false, true, false, false],
        I: [true, false, true, false, false],
        N: [true, false, true, false, false],
        G: [true, false, true, false, false],
        O: [true, false, true, false, false],
      },
      {
        B: [true, false, false, true, false],
        I: [true, false, false, true, false],
        N: [true, false, false, true, false],
        G: [true, false, false, true, false],
        O: [true, false, false, true, false],
      },
      {
        B: [true, false, false, false, true],
        I: [true, false, false, false, true],
        N: [true, false, false, false, true],
        G: [true, false, false, false, true],
        O: [true, false, false, false, true],
      },
      {
        B: [false, true, true, false, false],
        I: [false, true, true, false, false],
        N: [false, true, true, false, false],
        G: [false, true, true, false, false],
        O: [false, true, true, false, false],
      },
      {
        B: [false, true, false, true, false],
        I: [false, true, false, true, false],
        N: [false, true, false, true, false],
        G: [false, true, false, true, false],
        O: [false, true, false, true, false],
      },
      {
        B: [false, true, false, false, true],
        I: [false, true, false, false, true],
        N: [false, true, false, false, true],
        G: [false, true, false, false, true],
        O: [false, true, false, false, true],
      },
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [true, true, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [false, false, true, true, false],
        I: [false, false, true, true, false],
        N: [false, false, true, true, false],
        G: [false, false, true, true, false],
        O: [false, false, true, true, false],
      },
      {
        B: [false, false, true, false, true],
        I: [false, false, true, false, true],
        N: [false, false, true, false, true],
        G: [false, false, true, false, true],
        O: [false, false, true, false, true],
      },
      {
        B: [true, false, true, false, false],
        I: [true, false, true, false, false],
        N: [true, false, true, false, false],
        G: [true, false, true, false, false],
        O: [true, false, true, false, false],
      },
      {
        B: [false, true, true, false, false],
        I: [false, true, true, false, false],
        N: [false, true, true, false, false],
        G: [false, true, true, false, false],
        O: [false, true, true, false, false],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, false, false, true, true],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
      {
        B: [true, false, false, true, false],
        I: [true, false, false, true, false],
        N: [true, false, false, true, false],
        G: [true, false, false, true, false],
        O: [true, false, false, true, false],
      },
      {
        B: [false, true, false, true, false],
        I: [false, true, false, true, false],
        N: [false, true, false, true, false],
        G: [false, true, false, true, false],
        O: [false, true, false, true, false],
      },
      {
        B: [false, false, true, true, false],
        I: [false, false, true, true, false],
        N: [false, false, true, true, false],
        G: [false, false, true, true, false],
        O: [false, false, true, true, false],
      },
      {
        B: [true, false, false, false, true],
        I: [true, false, false, false, true],
        N: [true, false, false, false, true],
        G: [true, false, false, false, true],
        O: [true, false, false, false, true],
      },
      {
        B: [false, true, false, false, true],
        I: [false, true, false, false, true],
        N: [false, true, false, false, true],
        G: [false, true, false, false, true],
        O: [false, true, false, false, true],
      },
      {
        B: [false, false, true, false, true],
        I: [false, false, true, false, true],
        N: [false, false, true, false, true],
        G: [false, false, true, false, true],
        O: [false, false, true, false, true],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, false, false, true, true],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Any Two Vertical Lines",
    label: "Any Two Vertical Lines",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, true, true],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [true, true, true, true, true],
    },
    winningPatterns: [
      {
        B: [true, true, true, true, true],
        I: [true, true, true, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [true, true, true, true, true],
        I: [false, false, false, false, false],
        N: [true, true, true, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [true, true, true, true, true],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, true, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [true, true, true, true, true],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, true, true, true, true],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, true, true],
        N: [true, true, true, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, true, true],
        N: [false, false, false, false, false],
        G: [true, true, true, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, true, true, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, true, true, true, true],
        G: [true, true, true, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, true, true, true, true],
        G: [false, false, false, false, false],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [false, false, false, false, false],
        N: [true, true, true, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, true, true],
        N: [true, true, true, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, true, true, true],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, true, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, true, true],
        N: [false, false, false, false, false],
        G: [true, true, true, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, true, true, true, true],
        G: [true, true, true, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [true, true, true, true, true],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, true, true, true, true],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, true, true, true, true],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, true, true, true, true],
        G: [false, false, false, false, false],
        O: [true, true, true, true, true],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, true, true, true],
        O: [true, true, true, true, true],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Any Vertical Line",
    label: "Any Vertical Line",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, true, true],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    winningPatterns: [
      {
        B: [true, true, true, true, true],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, true, true, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, true, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, true, true, true, true],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Any Vertical, Diagonal, Or Horizontal Line",
    label: "Any Vertical, Diagonal, Or Horizontal Line",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, true],
      I: [true, true, true, true, true],
      N: [false, false, true, false, false],
      G: [false, true, true, false, false],
      O: [true, false, true, false, false],
    },
    winningPatterns: [
      {
        B: [false, false, false, false, false],
        I: [true, true, true, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [true, false, false, false, false],
        I: [false, true, false, false, false],
        N: [false, false, true, false, false],
        G: [false, false, false, true, false],
        O: [false, false, false, false, true],
      },
      {
        B: [false, false, false, true, false],
        I: [false, false, false, true, false],
        N: [false, false, false, true, false],
        G: [false, false, false, true, false],
        O: [false, false, false, true, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Arrow",
    label: "Arrow",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, false, true, false, false],
      N: [true, false, true, false, true],
      G: [false, true, true, true, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Arrow Frame",
    label: "Arrow Frame",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, true],
      I: [false, false, false, false, true],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [true, false, false, false, false],
    },
    unusedLetters: ["N", "G"],
    credit: null,
  },
  {
    value: "Arrowhead",
    label: "Arrowhead",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [true, false, false, false, false],
      G: [true, true, false, false, false],
      O: [true, true, true, false, false],
    },
    unusedLetters: ["B", "I"],
    credit: null,
  },
  {
    value: "Axe",
    label: "Axe",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, false, false, false],
      N: [true, true, true, false, false],
      G: [false, true, false, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["B"],
    credit: null,
  },
];
