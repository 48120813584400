export const fPatterns = [
  /* --------------------- F -------------------- */
  {
    value: "Ferris Wheel",
    label: "Ferris Wheel",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [true, true, true, false, true],
      N: [true, true, true, true, true],
      G: [true, true, true, false, true],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Field Goal",
    label: "Field Goal",
    otherNames: ["Goal Post"],
    pattern: {
      B: [true, true, true, false, false],
      I: [false, false, true, false, false],
      N: [true, false, true, true, true],
      G: [false, false, true, false, false],
      O: [true, true, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Fire Hydrant",
    label: "Fire Hydrant",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, true, false, true],
      N: [true, true, true, true, true],
      G: [false, true, true, false, true],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Fireplace",
    label: "Fireplace",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, true, false, false, true],
      N: [true, true, false, true, true],
      G: [true, true, false, false, true],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Fishing Rod",
    label: "Fishing Rod",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, true],
      I: [false, false, true, true, false],
      N: [false, true, false, false, false],
      G: [true, false, false, false, false],
      O: [false, true, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Flag",
    label: "Flag",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, true, true, false, false],
      N: [true, true, true, false, false],
      G: [true, true, true, false, false],
      O: [true, true, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Flag (Alternate)",
    label: "Flag (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [true, true, true, true, true],
      G: [true, true, false, false, false],
      O: [true, true, false, false, false],
    },
    unusedLetters: ["B", "I"],
    credit: null,
  },
  {
    value: "Flag Pole",
    label: "Flag Pole",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [true, true, true, true, true],
      G: [true, true, false, false, false],
      O: [true, true, false, false, false],
    },
    unusedLetters: ["B", "I"],
    credit: null,
  },
  {
    value: "Flag Reversed",
    label: "Flag Reversed",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, true, true, false, false],
      N: [true, true, true, false, false],
      G: [true, true, true, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Floating Block Of Nine",
    label: "Floating Block Of Nine",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, true, true, false],
      N: [false, true, true, true, false],
      G: [false, true, true, true, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: "Selena A Falcon",
    winningPatterns: [
      {
        B: [false, false, false, false, false],
        I: [false, true, true, true, false],
        N: [false, true, true, true, false],
        G: [false, true, true, true, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, true, true, false, false],
        G: [true, true, true, false, false],
        O: [true, true, true, false, false],
      },
    ],
  },
  {
    value: "Fly Swatter & Fly Right",
    label: "Fly Swatter & Fly Right",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, false, false, false, false],
      N: [true, true, true, false, false],
      G: [true, true, true, true, true],
      O: [true, true, true, false, false],
    },
    unusedLetters: ["I"],
    credit: null,
  },
  {
    value: "Four Arrows",
    label: "Four Arrows",
    otherNames: [],
    pattern: {
      B: [false, true, false, true, false],
      I: [true, true, false, true, true],
      N: [false, false, false, false, false],
      G: [true, true, false, true, true],
      O: [false, true, false, true, false],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Four Corners",
    label: "Four Corners",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, false, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: ["I", "N", "G"],
    credit: null,
  },
  {
    value: "Four Corners Small",
    label: "Four Corners Small",
    otherNames: ["Inside Square"],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, false, true, false],
      N: [false, false, false, false, false],
      G: [false, true, false, true, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "N", "O"],
    credit: null,
  },
  {
    value: "Four Dots - Horizontal/Vertical ",
    label: "Four Dots - Horizontal/Vertical ",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [true, false, false, false, false],
      N: [true, false, false, false, false],
      G: [true, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["O"],
    credit: null,
    winningPatterns: [
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [true, true, true, true, false],
      },
      {
        B: [false, false, true, false, false],
        I: [false, false, true, false, false],
        N: [false, false, true, false, false],
        G: [false, false, true, false, false],
        O: [false, false, false, false, false],
      },
    ],
  },
  {
    value: "Free",
    label: "Free",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, true, false, false],
      N: [false, true, true, false, false],
      G: [false, false, true, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Full 4",
    label: "Full 4",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [false, false, true, false, false],
      N: [false, false, true, false, false],
      G: [false, false, true, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Full Hourglass",
    label: "Full Hourglass",
    otherNames: ["Damro"],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, true, false, true, true],
      N: [true, true, true, true, true],
      G: [true, true, false, true, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
];
