import React from "react";
import paypal from "../assets/images/paypal.jpg";
import venmo from "../assets/images/venmo.jpg";
import buymeacoffee from "../assets/images/bmc_qr.png";
import karol from "../assets/images/karol-brennan-small.jpg";
import bingocaller from "../assets/images/bingo-caller.png";
import { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

export default function About() {
  useEffect(() => {
    document.title = "Let's Play Bingo! | About";
  });

  return (
    <section className="about-page">
      <div className="container row">
        <div className="col">
          <h1 className="no-margin">
            About
            <NavLink to="/" className={({ isActive }) => (isActive ? "hide" : "back-link")}>
              <button aria-label="Back to Game" className="primary-button">
                Back to Game
              </button>
            </NavLink>
          </h1>
          <p>
            Let's Play Bingo is a free bingo calling application with absolutely ZERO ADS. It can be used to host bingo games from anywhere: at home, at church, at the senior center, at the bar, even online through zoom or live streaming. With so many customizations to make your game your own it is the most powerful application of its kind available and is used all over the world. Send your friends the link so they can print their own bingo cards or follow along on screen with the new play along
            feature!
          </p>
        </div>
      </div>
      <div className="container row gutters-lg margin-vertical-xlg align-start desktop-no-wrap">
        <div className="col grow">
          {/* --------------- INTRO --------------- */}
          <h3 className="no-margin">
            Why <span className="notranslate">Let's Play Bingo</span>?
          </h3>
          <p>
            When I was a kid, my grandmother was living with us and she <strong>loved</strong> bingo. My mom would take her to the bingo hall and I would often tag along. When we couldn't get to the hall, and grandma would say "Let's Play Bingo!" we would use this little electronic bingo caller and paper cards we bought from a bingo supply store to play at home, betting pocket change on games. My grandmother passed away from cancer when I was 18, and while I have rarely gone to bingo since her
            passing, playing bingo at home with her are some of my fondest memories.
          </p>
          <p>
            <img src={bingocaller} alt="Photo of an electronic bingo game" className="bingo-caller-photo" />
            In 2016 while unpacking some boxes I came across the little bingo caller we used to play with. Finding that is what inspired me to build my own online bingo caller just for fun. So I created the original{" "}
            <a href="https://codepen.io/karolbrennan/pen/GxKZWX" target="_blank" rel="noreferrer" className="notranslate">
              Let's Play Bingo
            </a>
            . My hopes were that my little project could help bring families together on game night to play bingo the way I played with my mom and grandma at home. It quickly became the one application I've built that I am proudest of and I have spent the last several years improving it as my development skills improved.
          </p>
          <h4>March 2020</h4>
          <p>
            During the height of the pandemic - when everyone was forced into quaranting across the world - <span className="notranslate">Let's Play Bingo</span> started to really gain traction. I began getting emails from people all over the world thanking me for this little app I made and how it had helped keep their families in touch over virtual game nights. It was truly my biggest dreams for the site come alive.
          </p>
          <p>
            So I want to say a very sincere thank you to everyone who has reached out about the game. Thank you for sending me suggestions, alerting me to bugs, making donations, and sending me sweet little notes describing how you're using <span className="notranslate">Let's Play Bingo</span> to enrich your life or the lives of those around you. It really warms my heart. ❤
          </p>
          <p>
            - Karol
            <br />
            <em>In loving memory of Grandma Josephine</em>
          </p>
          <h2 className="padding-top-xxlg">Want to help improve the game?</h2>
          <p>
            Do you have ideas for how to make <span className="notranslate">Let's Play Bingo</span> even better? New game modes? New patterns? Something else?
          </p>
          <p>
            Contact me via the form on the <Link to="/contact">contact page</Link> I'd love to hear from you!
          </p>
        </div>
        <div className="col aside shrink">
          <h3>About the Developer</h3>
          <img src={karol} className="karol-headshot" alt="Photo of Karol Brennan" />
          <p>My name is Karol Brennan, I'm a software engineer from the Pacific Northwest in America. I'm the single mama of two awesome kids - an adult daughter and toddler son.</p>
          <p>
            I have been building websites for 2/3 of my life and it is by far my biggest passion. By day I work for a consulting firm and at night and on the weekends I work on <span className="notranslate">Let's Play Bingo</span>! When I am not programming, I enjoy spending time with my kids, playing video games, concerts and stand up comedy shows.
          </p>
        </div>
      </div>
      <div className="container row desktop-no-wrap margin-top-xxlg padding-top-xxlg gutters-lg">
        <div className="col">
          <h1 className="no-margin">Love the app? Want to show your support?</h1>

          <div className="row desktop-no-wrap gutters-md align-center justify-start">
            <div className="col grow wrap-text">
              <p>
                <span className="notranslate">Let's Play Bingo</span> is completely <strong>ad free</strong> and is run by a single developer. If you'd like to contribute toward the costs associated with running a website like this, or want to tip the developer just to say thanks, I am accepting donations via Buy Me A Cup of Tea, Paypal, or Venmo!
              </p>
              <p className="x-small-text">
                <strong>Buy Me a Cup of Tea</strong> allows you to make a donation using a credit/debit card processed securely through{" "}
                <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">
                  Stripe
                </a>{" "}
                with no need to log in or create an account!
              </p>
              <p className="x-small-text">Paypal allows you to donate automatically on a weekly or monthly basis!</p>
            </div>
            <div className="col shrink">
              <a href="https://www.buymeacoffee.com/letsplaybingo" target="_blank" className="qr-code-link" rel="noopener noreferrer">
                <img className="donate" src={buymeacoffee} alt="Buy Me A Coffee QR Code" />
                <br />
                Buy Me a Cup of Tea
              </a>
            </div>
            <div className="col shrink">
              <a href="https://www.paypal.com/donate/?hosted_button_id=JCL3339T3V7LJ" target="_blank" className="qr-code-link" rel="noopener noreferrer">
                <img className="donate" src={paypal} alt="Paypal.me name karolbrennan" />
                <br />
                Paypal
              </a>
            </div>
            <div className="col shrink">
              <a href="https://venmo.com/karolbrennan" target="_blank" className="qr-code-link" rel="noopener noreferrer">
                <img className="donate" src={venmo} alt="Venmo QR Code" />
                <br />
                Venmo
              </a>
            </div>
          </div>
        </div>
        <div className="col">
          <h4>Thanks/Credits</h4>
          <p>Everything on this site is original, with the exception of the following credits:</p>
          <p className="x-small-text">
            Fire/Hot Ball animation by{" "}
            <a href="https://2xsamurai.github.io/" target="_blank">
              Deepak K Vijayan
            </a>
            .
          </p>
          <p className="x-small-text">
            Icons by{" "}
            <a href="https://fontawesome.com/" target="_blank">
              FontAwesome
            </a>
          </p>
          <p className="x-small-text">
            Fonts powered by{" "}
            <a href="https://fonts.google.com" target="_blank">
              Google
            </a>
            .
          </p>
        </div>
      </div>
    </section>
  );
}
