export const oPatterns = [
  /* --------------------- O -------------------- */
  {
    value: "O",
    label: "O",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["B", "I", "N", "G"],
    credit: null,
  },
  {
    value: "O And P",
    label: "O And P",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, false, false, true],
      N: [true, true, true, true, true],
      G: [true, false, true, false, false],
      O: [true, true, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "O69",
    label: "O69",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, false, true, false, true],
      N: [true, true, false, true, true],
      G: [true, false, true, false, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: [],
    credit: "TOM",
  },
  {
    value: "Obtuse Angle ",
    label: "Obtuse Angle ",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, false, false, true, false],
      N: [false, false, false, false, true],
      G: [false, false, false, false, true],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "One Away",
    label: "One Away",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, true, true, true, true],
      N: [true, true, true, true, true],
      G: [true, true, true, false, true],
      O: [true, true, true, true, true],
    },
    winningPatterns: [
      {
        B: [true, true, true, true, true],
        I: [true, false, true, true, true],
        N: [true, true, true, true, true],
        G: [true, true, true, true, true],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, true, true, true, false],
        N: [true, true, true, true, true],
        G: [true, true, true, true, true],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, true, true, true, true],
        N: [true, true, true, true, true],
        G: [true, true, true, false, true],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, true, true, true, true],
        N: [false, true, true, true, true],
        G: [true, true, true, true, true],
        O: [true, true, true, true, true],
      },
      {
        B: [true, true, true, true, true],
        I: [true, true, true, true, true],
        N: [true, true, true, true, true],
        G: [true, false, true, true, true],
        O: [true, true, true, true, true],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "One Line",
    label: "One Line",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, false],
      I: [false, true, false, false, false],
      N: [false, false, true, false, false],
      G: [false, false, false, true, false],
      O: [false, false, false, false, true],
    },
    winningPatterns: [
      {
        B: [true, false, false, false, false],
        I: [false, true, false, false, false],
        N: [false, false, true, false, false],
        G: [false, false, false, true, false],
        O: [false, false, false, false, true],
      },
      {
        B: [false, true, false, false, false],
        I: [false, true, false, false, false],
        N: [false, true, false, false, false],
        G: [false, true, false, false, false],
        O: [false, true, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, true, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },

      {
        B: [false, false, false, false, true],
        I: [false, false, false, false, true],
        N: [false, false, false, false, true],
        G: [false, false, false, false, true],
        O: [false, false, false, false, true],
      },
      {
        B: [false, false, false, false, true],
        I: [false, false, false, true, false],
        N: [false, false, true, false, false],
        G: [false, true, false, false, false],
        O: [true, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, true, true, true],
        O: [false, false, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "One-Of-Each (All 3 Types Of Lines)",
    label: "One-Of-Each (All 3 Types Of Lines)",
    otherNames: [],
    pattern: { B: [true, false, false, false, false], I: [true, true, false, false, false], N: [true, false, true, false, false], G: [true, false, false, true, false], O: [true, true, true, true, true] },
    unusedLetters: [],
    credit: "Marianne",
    winningPatterns: [
      { B: [false, true, false, false, true], I: [false, true, false, true, false], N: [true, true, true, true, true], G: [false, true, false, false, false], O: [true, true, false, false, false] },
      { B: [true, true, true, true, true], I: [true, true, false, false, false], N: [true, false, true, false, false], G: [true, false, false, true, false], O: [true, false, false, false, true] },
    ],
  },
  {
    value: "One Sided",
    label: "One Sided",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, false, false, false, false],
      N: [false, false, false, false, false],
      G: [true, true, false, false, false],
      O: [true, true, false, false, false],
    },
    unusedLetters: ["B", "I", "N"],
    credit: null,
  },
  {
    value: "Outside Box Inside",
    label: "Outside Box Inside",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, true, false, true, false],
      N: [false, false, false, false, false],
      G: [false, true, false, true, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: ["N"],
    credit: null,
    winningPatterns: [
      {
        B: [true, false, false, false, true],
        I: [false, true, false, true, false],
        N: [false, false, false, false, false],
        G: [false, true, false, true, false],
        O: [true, false, false, false, true],
      },
      {
        B: [false, false, false, false, false],
        I: [false, true, false, true, false],
        N: [false, false, false, false, false],
        G: [false, true, false, true, false],
        O: [false, false, false, false, false],
      },
    ],
  },
  {
    value: "Overtime",
    label: "Overtime",
    otherNames: [],
    pattern: {
      B: [true, true, true, false, false],
      I: [true, false, true, false, false],
      N: [true, true, true, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["G", "O"],
    credit: null,
    winningPatterns: [
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, true, true, false, false],
        G: [true, false, true, false, false],
        O: [true, true, true, false, false],
      },
      {
        B: [false, false, true, true, true],
        I: [false, false, true, false, true],
        N: [false, false, true, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, true, true, true],
        G: [false, false, true, false, true],
        O: [false, false, true, true, true],
      },
    ],
  },
  {
    value: "Owl",
    label: "Owl",
    otherNames: [],
    pattern: {
      B: [true, false, true, true, false],
      I: [false, true, false, false, true],
      N: [false, false, true, false, true],
      G: [false, true, false, false, true],
      O: [true, false, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
];
