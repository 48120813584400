export const pPatterns = [
  /* --------------------- P -------------------- */
  {
    value: "Pacifier",
    label: "Pacifier",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, false, true, false],
      N: [true, true, true, false, true],
      G: [false, true, false, true, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Pacman",
    label: "Pacman",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, true, true, true, true],
      N: [true, true, true, true, true],
      G: [true, true, false, true, true],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Palm Tree",
    label: "Palm Tree",
    otherNames: [],
    pattern: {
      B: [false, true, false, false, false],
      I: [true, false, false, false, false],
      N: [false, true, true, true, true],
      G: [true, false, false, false, false],
      O: [false, true, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Pattern 1",
    label: "Pattern 1",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, false, true, false, false],
      N: [true, true, true, true, true],
      G: [false, false, true, false, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Pattern 2",
    label: "Pattern 2",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, true, false, true, false],
      N: [false, false, true, false, false],
      G: [false, true, false, true, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Pattern 3",
    label: "Pattern 3",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, false, false, false, false],
      N: [true, false, false, false, true],
      G: [false, false, false, false, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: ["I", "G"],
    credit: null,
  },
  {
    value: "Pattern 4",
    label: "Pattern 4",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [true, true, true, true, true],
      N: [false, false, true, false, false],
      G: [true, true, true, true, true],
      O: [false, false, true, false, false],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Pattern 5",
    label: "Pattern 5",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [false, true, false, false, false],
      N: [false, false, true, false, false],
      G: [false, false, false, true, false],
      O: [true, true, true, true, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Pattern 6",
    label: "Pattern 6",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, true],
      I: [false, true, true, true, false],
      N: [true, false, true, false, true],
      G: [false, true, true, true, false],
      O: [true, false, true, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Pattern 7",
    label: "Pattern 7",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, true, false, false, true],
      N: [true, false, true, true, true],
      G: [false, true, false, false, true],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Peace",
    label: "Peace",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [false, false, false, true, false],
      N: [true, true, true, true, true],
      G: [false, false, false, true, false],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Percent Sign",
    label: "Percent Sign",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, true],
      I: [true, true, false, true, false],
      N: [false, false, true, false, false],
      G: [false, true, false, true, true],
      O: [true, false, false, true, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Pickup Truck",
    label: "Pickup Truck",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, true, true, true, false],
      N: [false, true, true, false, false],
      G: [false, false, true, true, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: "Jeffrey Sargent",
  },
  {
    value: "Picnic Table",
    label: "Picnic Table",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [true, true, false, true, false],
      N: [true, false, true, false, false],
      G: [true, true, false, true, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Picnic Table Small",
    label: "Picnic Table Small",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, true],
      I: [false, false, true, true, false],
      N: [false, false, true, false, false],
      G: [false, false, true, true, false],
      O: [false, false, true, false, true],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Pillar",
    label: "Pillar",
    otherNames: [],
    pattern: {
      B: [true, true, true, true, true],
      I: [true, false, true, false, false],
      N: [true, true, true, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["G", "O"],
    credit: null,
  },
  {
    value: "Plus Sign",
    label: "Plus Sign",
    otherNames: [],
    pattern: {
      B: [false, false, true, false, false],
      I: [false, false, true, false, false],
      N: [true, true, true, true, true],
      G: [false, false, true, false, false],
      O: [false, false, true, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Pool Table",
    label: "Pool Table",
    otherNames: [],
    pattern: {
      B: [true, false, true, false, true],
      I: [false, false, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, false, false],
      O: [true, false, true, false, true],
    },
    unusedLetters: ["I", "N", "G"],
    credit: "Jordon",
  },
  {
    value: "Popeye",
    label: "Popeye",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, true, true],
      N: [false, true, true, true, false],
      G: [false, true, true, true, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "O"],
    credit: null,
  },
  {
    value: "Postage Plus One Line",
    label: "Postage Plus One Line",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [true, true, true, true, true],
      N: [false, false, false, false, false],
      G: [true, true, false, false, false],
      O: [true, true, false, false, false],
    },
    winningPatterns: [
      {
        B: [false, false, false, false, false],
        I: [true, true, true, true, true],
        N: [false, false, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [false, true, false, false, false],
        I: [false, true, false, false, false],
        N: [false, true, false, false, false],
        G: [false, true, false, true, true],
        O: [false, true, false, true, true],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, false, false, false, false],
        G: [true, true, true, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [true, true, false, false, true],
        I: [true, true, false, true, false],
        N: [false, false, true, false, false],
        G: [false, true, false, false, false],
        O: [true, false, false, false, false],
      },
    ],
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Postage Stamp",
    label: "Postage Stamp",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, false],
      I: [false, true, true, false, false],
      N: [false, true, true, false, false],
      G: [false, false, false, false, false],
      O: [false, false, false, false, false],
    },
    unusedLetters: ["B", "G", "O"],
    credit: null,
    winningPatterns: [
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, true, true, false, false],
        G: [false, true, true, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, true, true, false],
        N: [false, false, true, true, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, true, true, false],
        G: [false, false, true, true, false],
        O: [false, false, false, false, false],
      },
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [true, true, false, false, false],
        N: [true, true, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [true, true, false, false, false],
        G: [true, true, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [false, true, true, false, false],
        I: [false, true, true, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, true, true, false, false],
        O: [false, true, true, false, false],
      },
      {
        B: [false, false, true, true, false],
        I: [false, false, true, true, false],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, true, true, false],
        O: [false, false, true, true, false],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, true, true],
        N: [false, false, false, true, true],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, true, true],
        G: [false, false, false, true, true],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
    ],
  },
  {
    value: "Postage Stamps",
    label: "Postage Stamps",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, true, false, false, false],
      N: [false, false, false, false, false],
      G: [false, false, false, true, true],
      O: [false, false, false, true, true],
    },
    winningPatterns: [
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [false, false, false, false, false],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, false, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [true, true, false, true, true],
        I: [true, true, false, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, false, false],
        O: [false, false, false, false, false],
      },
      {
        B: [false, false, false, false, false],
        I: [false, false, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, false, true, true],
        O: [true, true, false, true, true],
      },
      {
        B: [true, true, false, false, false],
        I: [true, true, false, false, false],
        N: [false, false, false, false, false],
        G: [true, true, false, false, false],
        O: [true, true, false, false, false],
      },
      {
        B: [false, false, false, true, true],
        I: [false, false, false, true, true],
        N: [false, false, false, false, false],
        G: [false, false, false, true, true],
        O: [false, false, false, true, true],
      },
    ],
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Power T",
    label: "Power T",
    otherNames: [],
    pattern: {
      B: [true, true, false, false, false],
      I: [true, false, false, false, false],
      N: [true, true, true, true, true],
      G: [true, false, false, false, false],
      O: [true, true, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Pretzel",
    label: "Pretzel",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, true],
      I: [false, true, false, true, true],
      N: [false, false, true, false, true],
      G: [false, true, false, true, true],
      O: [false, true, true, true, true],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Propeller",
    label: "Propeller",
    otherNames: [],
    pattern: {
      B: [false, false, false, true, true],
      I: [false, false, false, true, false],
      N: [false, false, true, false, false],
      G: [false, true, false, false, false],
      O: [true, true, false, false, false],
    },
    unusedLetters: ["N"],
    credit: null,
  },
  {
    value: "Pumpkin",
    label: "Pumpkin",
    otherNames: [],
    pattern: {
      B: [false, false, true, true, false],
      I: [false, true, false, false, true],
      N: [true, true, false, false, true],
      G: [false, true, false, false, true],
      O: [false, false, true, true, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Pumpkin (Alternate)",
    label: "Pumpkin (Alternate)",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, true],
      I: [false, true, false, false, true],
      N: [true, true, false, false, true],
      G: [false, true, false, false, true],
      O: [false, true, true, true, true],
    },
    unusedLetters: [],
    credit: "Joetta",
  },
  {
    value: "Puppy",
    label: "Puppy",
    otherNames: [],
    pattern: {
      B: [false, true, false, false, false],
      I: [false, false, true, true, true],
      N: [false, false, true, true, false],
      G: [true, true, true, true, true],
      O: [true, true, false, false, false],
    },
    unusedLetters: [],
    credit: null,
  },
  {
    value: "Purse",
    label: "Purse",
    otherNames: [],
    pattern: {
      B: [false, true, true, true, true],
      I: [true, false, true, true, true],
      N: [true, false, true, true, true],
      G: [true, false, true, true, true],
      O: [false, true, true, true, true],
    },
    unusedLetters: [],
    credit: "Lindsey J",
  },
  {
    value: "Pyramid",
    label: "Pyramid",
    otherNames: [],
    pattern: {
      B: [false, false, false, false, true],
      I: [false, false, false, true, true],
      N: [false, false, true, true, true],
      G: [false, false, false, true, true],
      O: [false, false, false, false, true],
    },
    unusedLetters: [],
    credit: null,
  },
];
