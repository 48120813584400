export const xPatterns = [
  /* --------------------- X -------------------- */
  {
    value: "Xoxo ",
    label: "Xoxo ",
    otherNames: [],
    pattern: {
      B: [true, false, false, false, true],
      I: [false, false, false, true, false],
      N: [false, true, false, false, false],
      G: [false, false, false, true, false],
      O: [true, false, false, false, true],
    },
    unusedLetters: [],
    credit: "Alexis Montefalcon ",
  },
];
